import React from 'react';
import ReactDOM from 'react-dom';
import 'Config';
import App from './App';
import * as serviceWorker from './serviceWorker';
import OverlayApp from './components/Overlay/App';
import api from 'common/api';
import { errorRedirect, hasPermission, hasValidJwt, login } from './common/auth';

const showV3App = document.getElementById('root') && /.*\/v3\/.*/.test(window.location.href);
const onSignInPage = /.*\/SignIn.php.*/.test(window.location.href);
const loadOverlayApp = () => {
  if (window.location.pathname.substr(0, 4) === '/bo/') {
    if (!hasValidJwt()) {
      login().catch((error: { message: any; }) => {
        console.error(error);
        errorRedirect(error.message);
      });
    }

    if (!hasPermission('voice')) {
      return;
    }

    document.addEventListener('DOMContentLoaded', () => {
      const { body } = document;
      if (!body) {
        throw new Error('No body found.');
      }
      api.getWithLongCache('/company').then(() => console.log('loaded'));

      body.classList.remove('overlay-loading');

      // Add the main 'overlay' portion of the app for floating widgets, etc:
      const el = document.createElement('div');
      el.classList.add('flg-new');
      body.appendChild(el);

      api.getWithLongCache('/company').then((company: any) => {
        const voiceFeatureEnabled = /^voice(-|$)/.test(company?.ctiMode);
        ReactDOM.render(<OverlayApp showCallWidget={voiceFeatureEnabled}/>, el);
      }).catch((error) => {
        console.log(error);
      });
    });
  }
};

if (showV3App) {
  ReactDOM.render(<App />, document.getElementById('root'));
} else {
  if (!onSignInPage) {
    loadOverlayApp();
  }
}

serviceWorker.unregister();
