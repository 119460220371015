import React from 'react';

type Ref = {
  current: any
};

type Props = {
  innerRef: Ref
};

type State = {};

// Because we never ever want this iframe to reload once it's been loaded
// it needs to be in its own componenet with showComonentUpdate always false.
export default class ClassicNavbar extends React.Component<Props, State> {
  componentDidMount() {
    window.addEventListener('message', this.receiveMessage, false);
  }

  shouldComponentUpdate() {
    // never update otherwise the iframe will be reloaded.
    return false;
  }

  getBaseOrigin = (): string => {
    const { origin } = window.location;
    return origin.includes('localhost') ? 'https://demo.flg360.co.uk' : origin;
  };

  // Handle any messages from the navbar iFrame
  receiveMessage = (event: MessageEvent) => {
    // Only accept messages from the navbar's host.
    if (event.origin !== this.getBaseOrigin()) {
      return;
    }

    switch (event.data) {
      case 'open-help':
        (window as any).FreshworksWidget('open'); // Open the help centre.
        break;

      default:
        break;
    }
  };

  render() {
    const { innerRef } = this.props;
    return (
      <iframe
        ref={innerRef}
        src={`${this.getBaseOrigin()}/Navbar.php`}
        title="Side menu"
      />
    );
  }
}
