import styled from 'styled-components';
import { flgBlue } from './theme';

// A top-level object for holding the whole page in
export const PageContainer = styled.div`
  margin-left: 230px;
  height: 100vh;
  overflow: hidden;
  box-sizing: border-box;
`;

// A wrapper for icons in the header of modals
export const HeaderIconContainer = styled.div<{ background: string }>`
  grid-column: 1 / 2;
  grid-row: 1 / 3;
  border-radius: 50%;
  margin: auto 1em auto 0;
  height: 2.75em;
  width: 2.75em;
  background-color: ${(props) => props.background || flgBlue};
  display: flex;
  justify-content: center;
  align-items: center;

  .svg-inline--fa {
    height: 60%;
    width: 60%;
    color: white;
  }
`;
