import styled from 'styled-components';

import {
  secondaryText,
  divider,
  focusOrange,
  titleFontSize,
  flgPurple,
} from '../theme';
import { IconButton, ButtonishLink, ButtonishA } from '../buttons';

export const Header = styled.header`
  line-height: 1.4;
  position: relative;
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: auto 1fr auto;
  padding: 1.21em 2em; /* 1.21em is required to get it inline with the navbar divider*/
  border-bottom: 1px solid ${divider};
  // this is needed to stop certain pages being visible through it after a vertical scroll:
  background: white;
  h1 {
    font-weight: 400;
    grid-column: 2 / 3;
    grid-row: 2 / 3;
    margin: 0;
    font-size: ${titleFontSize};
  }
`;

export const Controls = styled.div`
  grid-column: 3 / 4;
  grid-row: 1 / 3;
  text-align: right;
  ${IconButton}, ${ButtonishLink}, ${ButtonishA} {
    font-size: 1.57em;
    width: 2em;
    height: 2em;
  }
`;

export const Breadcrumb = styled.div`
  grid-column: 2 / 3;
  grid-row: 1 / 2;
  color: ${secondaryText};
  a {
    &:before {
      content: "←";
      margin: 0 0.5em 0 0;
      font-family: sans-serif;
    }
    outline-color: ${focusOrange};
    text-decoration: none;
    color: ${secondaryText};
    &:hover {
      color: ${flgPurple};
      span {
        text-decoration: underline;
      }
    }
  }
`;
