/* Colours */
// Bright accent colours for buttons etc
export const flgLightBlue = '#66CCFF';
export const flgDarkBlue = '#2193CC';
export const flgPurple = '#705ce4';
export const flgPurpleAlt = '#6F5FE8';
export const flgLightPurple = '#C5BFF6';
export const dangerRed = '#D93025';
export const highlightRed = '#B4281F';

export const focusOrange = '#FFBF47';
export const flgGreen = '#50bc51';
export const flgPaleGreen = '#CDDC39';
export const flgViolet = '#9C27B0';
export const flgBlue = '#4285F4';
export const hoverBlue = '#3060B0';
export const secondaryBorder = '#C6DCFE';

// Our brand-purple-infused grey shades:
export const offBlack = '#1C042A';
export const darkerGray = '#52405E';
export const darkGrey = '#8D8194';
export const midGrey = '#c6c0ca';
export const lightGrey = '#e8e5e9';
export const offLightGrey = '#F9F8F4';
export const offWhite = '#f3f2f4';
export const lighterGrey = '#F4F6F9'; // Lighter than offWhite
export const disabledGrey = 'rgba(28, 4, 42, 0.5)'; // Our normal offBlack, but at 50% opacity
export const boxGrey = '#F8F7F9';

// Non-purple grey shades.
export const formDarkGrey = '#666666';
export const formLightBlue = '#F2F8FF';
export const formLightGrey = '#E6E6E6';
export const formMidGrey = '#BFBFBF';
export const formDisabledGrey = '#F6F6F6';
export const formOffBlack = '#2C2C2C';

// Semantic colour names
export const text = offBlack;
export const secondaryText = darkGrey;
export const background = offWhite;
export const divider = lightGrey;
export const emptyListPlaceholder = midGrey;

/* Spacing */
// The normal amount of margin around pages, modals, etc
export const margin = '2em';

/* Font sizes */
export const smallFontSize = '12px';
export const defaultFontSize = '16px';
export const largeFontSize = '20px';
export const titleFontSize = '24px';

/* The border-radius that we use for inputs, buttons, modals */
export const borderRadius = '2px';

// We are using an updated font just for form components and buttons.
export const newFont = 'font-family: -apple-system, system-ui, BlinkMacSystemFont, Helvetica, Arial, sans-serif;';
