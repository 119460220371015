import { IconDefinition, IconPrefix, IconName } from '@fortawesome/fontawesome-svg-core';

const width: number = 67;
const height: number = 68;
const ligatures: Array<any> = [];
const unicode: string = '';
const svgPathData: string = 'M17.0909 6C10.9656 6 6 10.9656 6 17.0909C6 23.2162 10.9656 28.1818 17.0909 28.1818C23.2162 28.1818 28.1818 23.2162 28.1818 17.0909C28.1818 10.9656 23.2162 6 17.0909 6ZM0 17.0909C0 7.65186 7.65186 0 17.0909 0C26.53 0 34.1818 7.65186 34.1818 17.0909C34.1818 25.6801 27.8458 32.7894 19.5928 34C27.8458 35.2106 34.1818 42.3199 34.1818 50.9091C34.1818 60.3481 26.53 68 17.0909 68C7.65186 68 0 60.3481 0 50.9091C0 42.3199 6.33597 35.2106 14.589 34C6.33597 32.7894 0 25.6801 0 17.0909ZM16.9545 16.4545C16.6784 16.4545 16.4545 16.6784 16.4545 16.9545C16.4545 17.2307 16.6784 17.4545 16.9545 17.4545C17.2307 17.4545 17.4545 17.2307 17.4545 16.9545C17.4545 16.6784 17.2307 16.4545 16.9545 16.4545ZM10.4545 16.9545C10.4545 13.3647 13.3647 10.4545 16.9545 10.4545C20.5444 10.4545 23.4545 13.3647 23.4545 16.9545C23.4545 20.5444 20.5444 23.4545 16.9545 23.4545C13.3647 23.4545 10.4545 20.5444 10.4545 16.9545ZM36.9688 17C36.9688 15.3431 38.3119 14 39.9688 14H63.9688C65.6256 14 66.9688 15.3431 66.9688 17C66.9688 18.6569 65.6256 20 63.9688 20H39.9688C38.3119 20 36.9688 18.6569 36.9688 17ZM17.0909 39.8182C10.9656 39.8182 6 44.7837 6 50.9091C6 57.0344 10.9656 62 17.0909 62C23.2162 62 28.1818 57.0344 28.1818 50.9091C28.1818 44.7837 23.2162 39.8182 17.0909 39.8182ZM36.9688 51C36.9688 49.3431 38.3119 48 39.9688 48H63.9688C65.6256 48 66.9688 49.3431 66.9688 51C66.9688 52.6569 65.6256 54 63.9688 54H39.9688C38.3119 54 36.9688 52.6569 36.9688 51Z';

const flgRadio: IconDefinition = {
  prefix: 'flg' as IconPrefix,
  iconName: 'radio' as IconName,
  icon: [width, height, ligatures, unicode, svgPathData],
};

export default flgRadio;
