import React, { useState } from 'react';
import styled from 'styled-components';
import Cleave from 'cleave.js/react';
// Not sure why eslint can't resolve this?
// eslint-disable-next-line import/no-unresolved
import { Props as CleaveProps } from 'cleave.js/react/props';
import renderIconHtml from '../fontawesome';
import {
  newFont,
  focusOrange,
  dangerRed,
  flgBlue,
  secondaryText,
  midGrey,
  borderRadius,
  formDarkGrey,
  formMidGrey,
  formOffBlack,
  formDisabledGrey,
  formLightGrey,
} from '../theme';
import { InputProps } from './InputProps';

// https://fontawesome.com/icons/check?style=regular
const tick = renderIconHtml(['fas', 'check'], 'white');
const exclamationTriangle = renderIconHtml(
  ['far', 'exclamation-triangle'],
  dangerRed,
);

export const borderBase = `
  border-radius: ${borderRadius};
  box-sizing: border-box;
`;

export const base = `
  ${newFont}
  font-size: inherit;
  padding: 0.6em 0.5em;
  line-height: 1.5;
  ${borderBase}
  ::placeholder { color: ${midGrey}; }
  &:disabled { color: ${formDarkGrey}; background-color: ${formDisabledGrey}; }
`;

export const focusRing = `
  &:focus {
    outline: none;
    box-shadow: 0 0 0 4px ${focusOrange};
  }
  /* Firefox adds an outline if you tab.  This could be useful for only showing the outline when you tab vs click but other browsers don't do this yet. */
  &::-moz-focus-inner {
    border: 0;
  }
`;

export const focusRingClass = `&.focus {
  outline: none;
  box-shadow: 0 0 0 4px ${focusOrange};
}`;

export const noMouseClickFocus = `&:focus:not(.focus-visible) {
  box-shadow: none;
}`;

export const focusRingAndKeyboardOnly = `
  ${focusRing}
  ${focusRingClass}
  ${noMouseClickFocus}
`;
// This CSS plus the listeners below lets us use native HTML validation without the annoyance of
// fields being flagged invalid before you've touched them.
export const invalidRing = `&:invalid:not(:focus) {
  &.touched,
  form .touched &,
  .submitted & {
    border-color: ${dangerRed};
  }
}`;

export const invalidRingClass = `&.invalid:not(:focus) {
  &.touched,
  form .touched &,
  .submitted & {
    border-color: ${dangerRed};
  }
}`;

type Submitted = { submitted: boolean };

export const Form = (props: any) => {
  const {
    onSubmit,
    children,
    style,
    id,
    className,
  } = props;
  const [state, setState] = useState<Submitted>({ submitted: false });
  const { submitted } = state;
  return (
    // TODO
    <form
      className={`${className} ${submitted ? 'submitted' : ''}`}
      onInvalid={() => setState({ submitted: true })}
      onSubmit={onSubmit}
      style={style}
      id={id}
    >
      {children}
    </form>
  );
};

document.addEventListener('focusout', (e: any) => e.target.classList.add('touched'));

export const focusAndInvalidRings = `
  ${focusRing}
  ${invalidRing}
`;

export const outline = `
  border: 1px solid ${formMidGrey};
  ${borderBase};
  &:focus {
    border-color: ${formOffBlack};
    &:not([type=radio]):not([type=checkbox]) {
      border-width: 2px;
      padding: calc(0.6em - 1px) calc(0.5em - 1px); /* Don't jump when the focus ring is applied. */
    }
  }
  &:not([type=radio]):not([type=checkbox]):disabled {
    border: none;
  }
`;

const formMargins = 'margin: 0.5em 0 0 0;';
const inlineMargins = 'margin: 0 1em;';

export const Label = styled.label<{ inline?: boolean }>`
  display: ${(props) => (props.inline ? 'inline-block' : 'block')};
  margin: 0 0 2em 0;
`;

export const FauxLabel = styled.h4`
  font-size: 1em;
  display: block;
  margin: 0 0 2em 0;
  font-weight: normal;
`;

const InputBase = `
  ${base}
  ${outline}
  ${focusAndInvalidRings}
  ${formMargins}
  display: block;
  width: 100%;
  &[type=radio] {
    ${outline}
    padding: 0.5em;
    border-width: 3px;
    display: inline-block;
    position: relative;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 26px;
    height: 26px;
    background: white;
    border-radius: 50%;
    &::before {
      transition: transform 300ms;
      position: absolute;
      display: block;
      overflow: hidden;
      margin: auto;
      left: 50%;
      top: 50%;
      width: 12px;
      height: 12px;
      content: '';
      background: ${flgBlue};
      border-radius: 50%;
      margin: -6px 0 0 -6px;
    }
    &:disabled::before {
      background-color: ${formLightGrey}
    }
    &:checked:not(:focus), &:hover {
      border: 3px solid ${flgBlue};
    }
    &:not(:checked):before { transform: scale(0); }
    margin-right: 0.5em;
    vertical-align: bottom;
    &:disabled, &:disabled:checked {
      border-color: ${formLightGrey}
    }
    &:enabled {
      cursor: pointer;
    }
  }
`;

const InlineBase = `
  ${inlineMargins}
  display: inline-block;
  width: 115px;
}
`;

export const Input = styled.input<{ ref?: string | React.RefObject<HTMLInputElement>, disabled?: boolean, inline?:boolean }>`
  ${InputBase}
  ${(props: any) => (props.inline ? InlineBase : '')}
`;

// This extra layer of components is because of this - https://styled-components.com/docs/faqs#why-am-i-getting-html-attribute-warnings
// By doing it this way we can avoid passing inline to Cleave and avoid any errors that causes.
export const CleaveComponent = ({
  inline,
  ...props
// eslint-disable-next-line react/no-unused-prop-types
}: CleaveProps & { inline?: boolean }) => (
  <Cleave {...props } />
);

export const FormattedInput = styled(CleaveComponent)`
  ${InputBase}
  ${(props) => ((props).inline ? InlineBase : '')}
`;

export const RadioGroup = styled.div`
  ${Label} {
    margin: 0 0 0 0;
  }
  margin: 0 0 2em 0;
`;

export const HorizontalRadioGroup = styled.div`
  display: flex;
  flex-direction: row;
  & ${Label} + ${Label}, ${Label} {
    margin: 0 2.6em 0 0;
  }
  & ${Input} {
    margin-right: 1em;
  }
  margin: 0 0 2em 0;
`;

const InputWithIconContainer = styled.div`
  display: flex;
  align-items: center;
  /* TO DO:  Currently using negative margin so that the field lines up correctly with the rest of the from.
   * There may cases we don't want that to happen, which means we will need a better way of doing this but for now this works.
   */
  ${formMargins}
  margin-right: -48px;

  input {
    margin-top: 0;
  }
`;

type InputWithIconProps = InputProps & {
  children: React.ReactNode | React.ReactNode[] | string,
  inputRef?: any,
  value: string,
  required: boolean
};

export const InputWithIcon = ({
  id,
  children,
  inputRef,
  disabled,
  inline,
  value,
  required,
  onChange,
  onBlur,
}: InputWithIconProps) => (
  <InputWithIconContainer>
    <Input
      id={id}
      ref={inputRef}
      disabled={disabled}
      inline={inline}
      value={value}
      required={required}
      onChange={onChange}
      onBlur={onBlur}
    />
    {children}
  </InputWithIconContainer>
);

export const TextArea = styled.textarea<{ type?: string }>`
  ${base}
  ${outline}
  ${focusAndInvalidRings}
  ${formMargins}
  display: block;
  width: 100%;
  height: 8em;
`;

// https://github.com/filamentgroup/select-css has a lot of good tips for doing selects:
export const Select = styled.select<{ inline?: boolean }>`
  ${base}
  ${outline}
  ${focusAndInvalidRings}
  ${(props: any) => (props.inline ? inlineMargins : formMargins)}
  background: white;
  display: ${(props: any) => (props.inline ? 'inline-block' : 'block')};
  width: ${(props: any) => (props.inline ? '120px' : '100%')};
  appearance: none;
  background-image: ${renderIconHtml(['fas', 'chevron-down'], formDarkGrey)};
  background-position: calc(100% - 0.75em) 50%;
  background-repeat: no-repeat;
  background-size: 1em;
  padding-right: 2.5em;
  &:focus {
    padding-right: calc(2.5em - 1px) !important;
    background-position: calc(100% - 0.75em + 1px) 50%; /* Don't jump when the focus ring is applied. */
    background-image: ${renderIconHtml(['fas', 'chevron-down'], 'black')};
  }
  &:disabled {
    background-image: ${renderIconHtml(['fas', 'chevron-down'], formMidGrey)};
  }
  &:enabled {
    cursor: pointer;
  }
`;

export type StyleProps = {
  style?: { marginRight: string },
  disabled?: boolean,
  checked?: boolean,
};

const PartialCheckbox = styled.input<StyleProps>`
  ${focusAndInvalidRings}
  ${outline}
  padding: 0.5em;
  font-size: 1em;
  border-width: 3px;
  appearance: none;
  width: 1.625em;
  height: 1.625em;
  background: white;
  vertical-align: -0.75em;
  &:hover {
    border-color: ${flgBlue};
  }
  &:checked {
    background-image: ${tick};
    background-size: 80%;
    background-position: 50% 55%;
    background-repeat: no-repeat;
    background-color: ${flgBlue};
    border-color: ${flgBlue};
  }
  &:checked:disabled {
    background-color: ${formLightGrey};
  }
  &:disabled {
    border-color: ${formLightGrey};
  }
  &:enabled {
    cursor: pointer;
  }
`;

export const StyledCheckbox = ({
  style,
  disabled,
  onChange,
  className,
  checked,
  dataTooltip,
}: {
  style?: any,
  className?: string;
  onChange: (event: any) => void;
  disabled?: boolean | null;
  checked?: boolean;
  dataTooltip?: string;
}) => (
  <PartialCheckbox
    type="checkbox"
    style={style}
    disabled={disabled as boolean}
    onChange={onChange}
    className={className}
    checked={checked}
    data-tooltip={dataTooltip}
  />
);

const CheckboxLabel = styled(Label)<{ disabled?: boolean | null }>`
  ${(props: any) => props.disabled && `color: ${formMidGrey};`};
  ${(props: any) => !props.disabled && 'cursor: pointer;'};
  display: flex;
  align-items: center;
  & + & {
    margin-top: -1em;
  }
  & input[type="radio"] {
    margin-top: 0;
  }
`;

// Sometimes we need the label to be grey too when the checkbox is disabled.
export const LabelledCheckbox = ({
  children,
  className,
  disabled,
  checked,
  onChange,
  dataTooltip,
  invert,
}: {
  children: React.ReactNode | React.ReactNode[] | string;
  className?: string;
  onChange: (event: any) => void;
  disabled?: boolean | null;
  checked?: boolean;
  dataTooltip?: string,
  invert?: boolean;
}) => {
  const Styled = () => (
    <StyledCheckbox
      onChange={onChange}
      style={{ marginRight: '1em' }}
      checked={checked}
      disabled={disabled}
      className={className}
      dataTooltip={dataTooltip}
    />
  );
  return (invert ? (
    <CheckboxLabel disabled={disabled}>
      {children}
      <Styled />
    </CheckboxLabel>
  ) : (
    <CheckboxLabel disabled={disabled}>
      <Styled />
      {children}
    </CheckboxLabel>
  ));
};

export const LabelledRadioButton = ({
  children,
  disabled,
  onChange,
  checked,
  value,
}: {
  children: React.ReactNode | React.ReactNode[] | string,
  onChange: (event: any) => void,
  disabled?: boolean,
  checked?: boolean,
  value?: string | number | string[] | undefined,
}) => (
  <CheckboxLabel disabled={disabled}>
    <Input
      type="radio"
      onChange={onChange}
      checked={checked}
      value={value}
      disabled={disabled}
    />
    {children}
  </CheckboxLabel>
);

export const HelpText = styled.p`
  color: ${secondaryText};
  margin: -1.4em 0 2em 0;
`;

export const WarningText = styled.p`
  color: ${dangerRed};
  margin: 1.5em 0;
  padding: 0 0 0 1.5em;
  background-image: ${exclamationTriangle};
  background-repeat: no-repeat;
  background-size: 1em;
`;
