// Takes a URL and encodes it in a format that classic expects
export function translateToClassicUrl(urlToEncode: string): string {
  // Get the querystring params (everything after ?);
  const splitUrl = urlToEncode.split('?');
  const encodedParams = btoa(splitUrl[1]);
  return `${splitUrl[0]}?${encodedParams}`;
}

// Takes Classic's base64 parameters and returns a straightforward KVP set.
export function decodeClassicUrl(searchString: string): {
  [K in string]: string;
} {
  const cleanedString = searchString.replace(/^.*\?/, '').replace(/#.*$/, '');
  const output: {
    [K in string]: string;
  } = {};
  atob(cleanedString).split('&').forEach((param) => {
    const [key, ...value] = param.split('=');
    output[key] = value.join('=');
  });

  return output;
}

export function leadUrl(id: string): string {
  return translateToClassicUrl(`/bo/BOLeadSummary.php?intLeadID=${id}`);
}
