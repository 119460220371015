import { useEffect } from 'react';

const EXCLUDED_URL_PATTERNS = [/upload-documents/, /appointments\/book/, /journeys\//];

function HelpButton() {
  useEffect(() => {
    if (EXCLUDED_URL_PATTERNS.filter((pattern) => pattern.test(window.location.href)).length === 0) {

      const script = document.createElement('script');
      script.src = 'https://euc-widget.freshworks.com/widgets/103000008094.js';
      script.async = true;
      script.defer = true;
      script.integrity = 'sha384-bmDSMCfCyfStrexIYAH5lA+ZZHV6M4mrCvwjx+saLGVbg2ADrhnNGUiCj5r7rsa/';
      script.crossOrigin = 'anonymous';
      document.body.appendChild(script);

      (window as any).fwSettings = {
        'widget_id':103000008094,
      };

      // Cleanup function to remove the script when the component unmounts
      return () => {
        document.body.removeChild(script);
      };
    }
  }, []);

  return null;
}

export default HelpButton;