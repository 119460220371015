import React from 'react';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import normalisePhoneNumber from 'shared/normalise-phone-number';

// Standardises the formatting of a phone number if it can,
// otherwise just returns the raw nonsense.
export function formatPhoneNumber(raw: string): string {
  const norm = normalisePhoneNumber(raw);
  if (!norm) return raw;
  const parsed = parsePhoneNumberFromString(norm);
  return parsed ? parsed.formatInternational() : norm;
}

function span(number: string, props: { [key: string]: any }) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <span {...props}>{number}</span>;
}

// An inline phone number control. Not very good yet —
// doesn't support our API-driven tel-link equivalent,
// for example — but that can come later. Does everything we need for now.
export default function PhoneNumber({
  number,
  telLink,
  ...props
}: {
  number: string,
  telLink?: boolean,
  [key: string]: any
}) {
  const normalised = normalisePhoneNumber(number);
  if (!normalised) return span(number, props);

  const parsed = parsePhoneNumberFromString(normalised);
  if (!parsed) return span(number, props);

  if (telLink) {
    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <a href={parsed.getURI()} {...props}>
        {parsed.formatInternational()}
      </a>
    );
  }

  return span(parsed.formatInternational(), props);
}
