import React from 'react';
import styled from 'styled-components';
import ClassicNavbar from './ClassicNavbar';
import { background } from '../theme';

const Container = styled.div`
  background: ${background};
  width: 230px;
  position: fixed;
  z-index: 1;
  height: 100%;
  left: 0;
  iframe {
    height: 100%;
    width: 100%;
    border: 0;
  }
`;
const Overlay = styled.div`
  background: transparent;
  top: 0;
  left: 230px; /* Don't overlay the iframe otherwise you'll never see the modals */
  right: 0;
  bottom: 0;
  z-index: 2;
  position: fixed;
  touch-action: none;
`;

type Props = {};

type State = {
  loading: boolean,
  modalOpen: boolean
};

type Ref = {
  current: any
};

export default class Sidebar extends React.Component<Props, State> {
  iframe: Ref = React.createRef();

  constructor(prop: Props) {
    super(prop);
    this.state = {
      loading: true,
      modalOpen: false,
    };
  }

  componentDidMount = () => {
    if (this.iframe && this.iframe.current) {
      this.iframe.current.addEventListener('load', this.onFrameLoad);
    }
    window.addEventListener('message', this.receiveMessage, false);
  };

  componentWillUnmount = () => {
    if (this.iframe && this.iframe.current) {
      this.iframe.current.removeEventListener('load', this.onFrameLoad);
    }
    window.removeEventListener('message', this.receiveMessage, false);
  };

  onFrameLoad = () => {
    this.setState({ loading: false });
  };

  receiveMessage = (message: any) => {
    switch (message.data) {
      case 'OPEN_MODAL':
        this.setState({ modalOpen: true });
        break;

      case 'CLOSE_MODAL':
        this.setState({ modalOpen: false });
        break;

      default:
        break;
    }
  };

  closeModal = () => {
    this.setState({ modalOpen: false });
    this.iframe.current.contentWindow.postMessage('CLOSE_MODAL', '*');
  };

  render = () => {
    const { loading, modalOpen } = this.state;
    return (
      <>
        <Container
          className={`sidemenu-container ${
            loading ? 'loading' : ''
          }`}
        >
          <ClassicNavbar innerRef={this.iframe} />
        </Container>
        { modalOpen ? (
          <Overlay
            className="sidemenu-modal-overlay"
            onClick={this.closeModal}
          />
        ) : null}
      </>
    );
  };
}
