import React from 'react';
import styled from 'styled-components';
import { formatDate, justTime } from '../shared/date-utils';

const oneMinute = 60000;
const oneHour = 60 * oneMinute;
const oneDay = 24 * oneHour;

type PropsType = {
  date: Date | number | void | null;
  capitalise?: boolean;
  style?: { fontWeight: string };
  [key: string]: any,
};

const Styled = styled.span`
  padding-bottom: 0.1em;
  border-bottom: 1px dotted currentColor;
`;

function capitaliseText(t: string): string {
  return t[0].toUpperCase() + t.substr(1);
}

function dateStamp(timestamp: number): number {
  return Math.floor(timestamp / oneDay);
}

export function pastDateString(timestamp: number | null | undefined): string {
  if (!timestamp) return 'never';

  const now = Date.now();
  const today = dateStamp(now);
  const day = dateStamp(timestamp);
  const date = new Date(timestamp);

  if (day >= today) {
    const diff = now - timestamp;
    if (diff > oneHour) {
      const hoursAgo = Math.round(diff / oneHour);
      return hoursAgo === 1
        ? 'an hour ago'
        : `today at ${justTime(date)}`;
    }
    if (diff > oneMinute) {
      const minutesAgo = Math.round(diff / oneMinute);
      return minutesAgo === 1
        ? 'a minute ago'
        : `${minutesAgo} minutes ago`;
    }
    return 'just now';
  }
  const daysAgo = today - day;
  if (daysAgo === 1) return `yesterday at ${justTime(date)}`;
  if (daysAgo < 10) return `${daysAgo} days ago`;
  return formatDate(date, 'd MMMM yyyy');
}

export function futureDateString(timestamp: number | null | undefined): string {
  if (!timestamp) return 'never';

  const now = Date.now();
  const today = dateStamp(now);
  const day = dateStamp(timestamp);
  const date = new Date(timestamp);

  if (day <= today) {
    const diff = timestamp - now;
    if (diff > oneHour) {
      const hoursAgo = Math.round(diff / oneHour);
      return hoursAgo === 1 ? 'in an hour' : `in ${hoursAgo} hours`;
    }
    if (diff > oneMinute) {
      const minutesAgo = Math.round(diff / oneMinute);
      return minutesAgo === 1
        ? 'in a minute'
        : `in ${minutesAgo} minutes`;
    }
    return 'any time now';
  }
  const daysFromNow = day - today;
  if (daysFromNow === 1) return `tomorrow at ${justTime(date)}`;
  if (daysFromNow < 10) return `in ${daysFromNow} days`;
  return formatDate(date, 'd MMMM yyyy');
}

export function relativeDateString(
  timestamp: number | null | undefined,
): string {
  if (!timestamp) return 'never';
  const diff = timestamp - Date.now();
  if (Math.abs(diff) < oneMinute) return 'about now';
  return diff > 0 ? futureDateString(timestamp) : pastDateString(timestamp);
}

function display(
  relativeDateStr: string,
  date: Date | null,
  capitalise: boolean,
  props: any,
): React.ReactElement<'span'> {
  return date ? (
    // TODO: Remove prop spreading
    // eslint-disable-next-line
    <Styled title={formatDate(date)} {...props}>
      {capitalise
        ? capitaliseText(relativeDateStr)
        : relativeDateStr}
    </Styled>
  ) : (
    <span>{capitalise ? 'Never' : 'never'}</span>
  );
}

function parseDate(
  d: Date | number | void | null,
): { timestamp: number | null; date: Date | null } {
  if (!d) return { timestamp: null, date: null };

  const timestamp = d instanceof Date ? d.getTime() : d;
  return {
    timestamp,
    date: new Date(timestamp),
  };
}

export default function RelativeDate({
  date: inputDate,
  capitalise,
  ...props
}: PropsType) {
  const { timestamp, date } = parseDate(inputDate);
  return display(
    relativeDateString(timestamp),
    date,
    capitalise || false,
    props,
  );
}

export function PastDate({
  date: inputDate,
  capitalise,
  ...props
}: PropsType) {
  const { timestamp, date } = parseDate(inputDate);
  return display(pastDateString(timestamp), date, capitalise || false, props);
}

export function FutureDate({
  date: inputDate,
  capitalise,
  ...props
}: PropsType) {
  const { timestamp, date } = parseDate(inputDate);
  return display(futureDateString(timestamp), date, capitalise || false, props);
}
