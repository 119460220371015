import { IconPrefix, IconName, IconProp } from '@fortawesome/fontawesome-svg-core';
import { DataType, DefaultInputStyle } from './types/field';
import {
  flgPurple,
  flgLightBlue,
  flgDarkBlue,
  flgGreen,
  dangerRed,
  flgViolet,
} from './theme';

export function prepSvg(svg: string): string {
  return `url('data:image/svg+xml;charset=US-ASCII,${encodeURIComponent(
    svg,
  )}')`;
}

// NOTE: when editing the colours/icons here, also update Container in FieldItemContainer.js:
//

export function iconFromDataType(dataType: DataType, defaultInputStyle?: DefaultInputStyle): {
  icon: IconProp,
  color: string
} {
  switch (dataType) {
    // These are all numbers, so they can be the same colour but different icons:
    case 'currency':
      return { icon: ['flg' as IconPrefix, 'currency' as IconName], color: flgPurple };
    case 'decimal':
      return { icon: ['flg' as IconPrefix, 'decimal' as IconName], color: flgPurple };
    case 'number':
    case 'integer':
      return { icon: ['far', 'hashtag'], color: flgPurple };
    case 'calculation':
      return { icon: ['far', 'calculator'], color: flgPurple };
    case 'percent':
      return { icon: ['far', 'percent'], color: flgPurple };
    // These are all time things, they can all be the same colour.
    case 'datetime':
      return { icon: ['flg' as IconPrefix, 'datetime' as IconName], color: dangerRed };
    case 'date':
      return { icon: ['far', 'calendar'], color: dangerRed };
    case 'time':
      return { icon: ['far', 'clock'], color: dangerRed };
    // No need to distinguish between sets and lookups
    // unless you want your sets to be styled as radios
    case 'set':
      if (defaultInputStyle && defaultInputStyle === 'radio') {
        return { icon: ['flg' as IconPrefix, 'radio' as IconName], color: flgGreen };
      }
      return { icon: ['flg' as IconPrefix, 'selection' as IconName], color: flgGreen };
    case 'lookup':
      return { icon: ['flg' as IconPrefix, 'selection' as IconName], color: flgGreen };
    case 'multiselect':
      return { icon: ['flg' as IconPrefix, 'checklist' as IconName], color: flgGreen };
    case 'boolean':
      return { icon: ['far', 'check-square'], color: flgGreen };
    // These are text fields with in-built validation rules
    // so they can be a colour group
    case 'email':
      return { icon: ['far', 'envelope'], color: '#ffaa00' };
    case 'postcode':
      return { icon: ['far', 'map-marker-alt'], color: '#ffaa00' };
    case 'phone':
      return { icon: ['far', 'phone'], color: '#ffaa00' };
    case 'address':
      return { icon: ['flg' as IconPrefix, 'address' as IconName], color: '#ffaa00' };
    // These are all text fields so they can all be the same colour
    // but with semantic icons when we know what kind of thing it is
    case 'textlong':
      return { icon: ['far', 'align-left'], color: flgLightBlue };
    case 'text':
      return { icon: ['far', 'font'], color: flgLightBlue };
    case 'signature':
      return { icon: ['far', 'signature'], color: flgDarkBlue };
    case 'table':
      return { icon: ['far', 'table'], color: flgViolet };
    default:
      return { icon: ['far', 'font'], color: flgLightBlue };
  }
}
