import * as React from 'react';
import log from 'logger';
import LeadLink from '../LeadLink';
import { BreadcrumbProp } from './types';
import { decodeClassicUrl } from '../utils/classic-url-helper';

function getTitle(url: string): React.ReactNode {
  // The job of this function is to take a URL and work out what text to
  // display in a link to that URL.

  const urlBase = url
    .substr(1) // Remove leading '/'
    .replace(/\/?(\?.*)?$/, '') // Remove query string
    .replace(/([0-9a-f]{4}-?){8}/gi, ':guid') // Replace GUID with literal marker
    .replace(/\d{4,}/g, ':number'); // Replace other IDs with literal marker

  switch (urlBase) {
    case 'voice':
      return 'call history';
    case 'journeys':
      return 'journey list';
    case 'appointments':
      return 'appointment types';
    case 'appointments/calendars':
      return 'calendar settings';
    // TODO: Might be nice if these included the name of the journey:
    case 'journeys/journey/:guid':
      return 'journey';
    case 'journeys/journey/:guid/lead-dashboard/:number':
      return 'journey dashboard';
    case 'journeys/journey/:guid/task/:number/:number':
      return 'journey task';
    case 'bo/BOUserProfile.php':
      return 'profile';
    case 'platform/copy-account':
      return 'copy account setup';
    case 'bo/BOLeadSummary.php':
      try {
        const { intLeadID } = decodeClassicUrl(url);
        // This should never happen but just to cover all bases:
        if (!intLeadID) return 'FLG CRM';
        return (
          <LeadLink
            leadId={intLeadID}
            displayName
            hideIcon
            inlineId
            addWordLead
            noLink
            capitalise={false}
          />
        );
      } catch (e) {
        log.warn(e);
        return 'previous page';
      }
    default:
      log.warn(
        `Unrecognised referrer URL: ${url} — this won't break anything but it should be added to getTitle in back-link-details.js so we can display proper breadcrumbs in future.`,
      );
      return 'previous page';
  }
}

type BackLinkDetails = {
  url: string | null,
  title: React.ReactNode,
  external: boolean
} | null;

type BackLinkDefault = {
  def?: BreadcrumbProp,
  alwaysUseDefault?: boolean,
};

export default function backLinkDetails(
  ref: string | null,
  prefix: string,
  {
    def,
    alwaysUseDefault,
  }: BackLinkDefault,
): BackLinkDetails {
  // Unless configured to always override it with the default breadcrumb, our preference is
  // to generate a breadcrumb from the 'referrer' URL passed in as `ref`:
  if (!alwaysUseDefault) {
    if (ref) {
      const external = ref !== prefix && ref.indexOf(`${prefix}/`) !== 0;
      const backUrl = external ? ref : ref.substr(prefix.length);
      return {
        url: backUrl,
        title: (
          <>Return to {getTitle(backUrl)}</>
        ),
        external,
      };
    }
    // If there is no default then generate a simple 'home' link — we also want to do this only
    // if alwaysUseDefault is false, because if it's true and the default is null then that's an
    // instruction not to draw a breadcrumb at all (which we'll handle later).
    if (!def) return { url: '/', title: 'Return home', external: true };
  }

  const breadcrumb = def;

  if (!breadcrumb) {
    return null;
  }

  if (!breadcrumb.url) {
    return breadcrumb.title
      ? { url: null, title: breadcrumb.title, external: false }
      : null;
  }

  return {
    url: breadcrumb.url,
    // If 'title' is null it's because we're directing to (eg) a lead summary page,
    // and we don't know what the text for that should say until we load the lead details
    // (which is handled by the <LeadLink> node returned by getTitle):
    title: (
      <>
        Return to{' '}
        {breadcrumb.title || getTitle(breadcrumb.url)}
      </>
    ),
    external: !!breadcrumb.external,
  };
}
