import { queryParams } from '../../common/utils/query-string';

export function decodeClassicQueryString(q: string): {
  [K in string]: string;
} {
  let query = q;
  let decoded = '';

  while (query.length % 4) {
    // Pad until it's valid base-64
    query += '=';
  }

  try {
    decoded = atob(query);
  } catch (e: any) {
    console.log(`Unable to decode ${query}: ${e.toString()}`);
  }

  return queryParams(decoded);
}

export function queryParameters(): {
  [K in string]: string;
} {
  return decodeClassicQueryString(window.location.search.substr(1));
}
