import styled from 'styled-components';
import ReactModal from 'react-modal';
import * as React from 'react';
import { Header } from '../Header/styled';

import { lightGrey, titleFontSize, borderRadius } from '../theme';

export const ModalContent = styled.div`
  grid-row: 2 / 3;
  grid-column: 1 / 3;
  border-bottom: 1px solid ${lightGrey};
  padding: 2em 2.5em;
  overflow: auto;
  > p:first-child {
    margin-top: 0;
  }
`;

export const CancelButtonContainer = styled.div`
  grid-row: 3 / 4;
  grid-column: 1 / 2;
  padding: 1.5em 0 1.5em 2.5em;
`;

export const OkButtonContainer = styled.div`
  grid-row: 3 / 4;
  grid-column: 2 / 3;
  text-align: right;
  padding: 1.5em 2.5em 1.5em 0;
`;

const PartlyStyledModal = styled(ReactModal)`
  z-index: 1000;
  position: fixed !important;
  top: 0;
  background: white;
  min-height: 19em;

  &:focus {
    outline: none;
  }
  > * {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: grid;
    grid-template-rows: auto 1fr auto;
    grid-template-columns: auto auto;
    > header {
      grid-row: 1 / 2;
      grid-column: 1 / 3;
    }
  }
  /* box-shadow: 0 .5em 15em #1C042A, 0 .5em 3em #1C042A; */
`;

const SlideoutModal = styled(PartlyStyledModal)<{ width?:number }>`
  right: -${(props) => props.width || 40}rem;
  height: 100vh;
  width: ${(props) => props.width || 40}rem;
  transition: transform 300ms;
  &.ReactModal__Content--after-open:not(.ReactModal__Content--before-close) {
    transform: translateX(-${(props) => props.width || 40}rem);
  }
  > form {
    height: 100vh;
  }
  ${Header} {
    padding-left: 2.5em;
    padding-right: 2.5em;
    > div {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
`;

const CenteredModal = styled(PartlyStyledModal)`
  top: 50%;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 30em;
  border-radius: ${borderRadius};
  opacity: 0;
  /* transition: transform 300ms ease-in; */
  transform: translateY(100vh);
  &.ReactModal__Content--after-open:not(.ReactModal__Content--before-close) {
    opacity: 1;
    transition: opacity 250ms ease-in;
    transform: translateY(-50%);
    /* transition: transform 300ms ease-out; */
  }
  > div {
    min-height: 19em;
  }
  h1 {
    font-size: ${titleFontSize};
  }

  ${CancelButtonContainer},
  ${OkButtonContainer},
  ${ModalContent},
  ${Header} {
    padding: 1em;
    border-bottom: none;
  }

`;
try {
  ReactModal.setAppElement('#root');
} catch (e) {
  /* This will get caught if if cannot find '#root' in the document which will most likely
     occur when running tests.
  */
}

type Props = {
  centered: boolean,
  isOpen: boolean,
  width?: number,
  onRequestClose: (
    event: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>
  ) => void,
  onAfterOpen: ReactModal.OnAfterOpenCallback | undefined,
  style: ReactModal.Styles,
  className: string | ReactModal.Classes,
  children: React.ReactNode,
};

export const StyledModal = ({
  centered,
  isOpen,
  width,
  onRequestClose,
  style,
  className,
  onAfterOpen,
  children,
}: Props) => {
  let Modal = SlideoutModal;
  if (centered) Modal = CenteredModal;
  return (
    <Modal
      closeTimeoutMS={300}
      overlayClassName="modal-overlay"
      isOpen={isOpen}
      width={width}
      onRequestClose={onRequestClose}
      style={style}
      className={className}
      onAfterOpen={onAfterOpen}
    >
      {children}
    </Modal>
  );
};
