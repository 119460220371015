import styled from 'styled-components';
import { Container as EmptyListPlaceholder } from '../../../common/EmptyListPlaceholder';
import { CallIconContainer } from '../../Voice/CallLog/CallIcon';
import {
  borderRadius,
  divider,
  secondaryText,
  offBlack,
  midGrey,
  dangerRed,
  flgPurple,
  focusOrange,
} from '../../../common/theme';

// eslint-disable-next-line import/prefer-default-export
export const Container = styled.div`
  position: fixed;
  top: 2em;
  right: 0;
  background: white;
  border-radius: ${borderRadius} 0 0 ${borderRadius};
  box-shadow: 0 2px 0 ${divider};

  header {
    /* What is this colour? It isn't in the theme: */
    background: #f9fafc;
    display: flex;
    padding: 1em;
    border-bottom: 1px solid ${divider};
    border-radius: ${borderRadius} 0 0 0;
    svg {
      display: none;
    }
    h1 {
      font-weight: normal;
      flex: 1;
      font-size: inherit;
      margin: 0 1em 0 0;
      color: ${secondaryText};
      transition: opacity 450ms;
    }
    a {
      flex: 0 0 auto;
    }
  }

  ${EmptyListPlaceholder} {
    svg {
      font-size: 0.5em;
    }
    margin: 3em 0;
  }

  ol {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 14px;
    &.loading {
      height: 10em;
    }
    li {
      &.archived-call {
        color: ${midGrey};
        .call-timestamp,
        .call-timestamp span {
          color: ${midGrey} !important;
        }
      }
      &:not(:last-child) {
        border-bottom: 1px solid ${divider};
      }
      > * {
        padding: 1em;
        display: grid;
        grid-template-rows: auto auto auto;
        grid-template-columns: 2.5em 10.5em 4em;
        &:focus {
          &.focus-visible {
            box-shadow: 0 0 0 4px ${focusOrange} inset;
          }
          outline: none;
        }
      }
      ${CallIconContainer} {
        grid-row: 1 / 3;
        grid-column: 1 / 2;
        svg {
          height: 1.5em;
          transform: translate(0, 0.75em);
        }
      }
      .phone-number {
        grid-row: 1 / 2;
        grid-column: 2 / 3;
        margin-bottom: 0.25em;
        transition: opacity 450ms;
      }
      .call-timestamp {
        grid-row: 2 / 3;
        grid-column: 2 / 3;
        color: ${secondaryText};
        span {
          color: ${secondaryText};
        }
        transition: opacity 450ms;
      }
      .alert-text {
        grid-row: 1 / 3;
        grid-column: 2 / 3;
        transition: opacity 450ms;
      }
      .expander-space {
        grid-row: 1 / 3;
        grid-column: 3 / 4;
        transition: transform 450ms;
        > div {
          width: 100%;
          padding-top: 0.2em;
          div,
          .unmatched-call-alert-icon {
            height: 1.5em;
            width: 0.8em;
            font-size: 1.2em;
            display: inline-block;
            margin-right: 0.5em;
            svg {
              transform: translate(-2px, 2px);
            }
          }
          .unmatched-call-alert-icon {
            width: 2em;
            height: 1.7em;
            color: ${dangerRed};
            transition: transform 450ms;
            transform: translate(-187px, 0);
          }
          > svg:not(unmatched-call-alert-icon) {
            transform: translate(0, -2px);
            color: black;
            transition: opacity 450ms;
          }
        }
        > a {
          display: none;
        }
      }
      .expanded-content {
        grid-row: 3 / 4;
        grid-column: 1 / 4;
        padding-top: 1.2em;
        .lead {
          display: block;
        }
      }
      &.expanded-call {
        .expander-space {
          > a {
            display: inline;
          }
          > div {
            display: none;
          }
        }
      }
      &:not(.expanded-call) {
        cursor: pointer;
      }
      &.unmatched-call-alert {
        text-decoration-color: ${flgPurple};
        color: ${flgPurple};
        &:not(:hover) {
          text-decoration: underline;
        }
      }
    }
  }

  @media only screen and (max-width: 1188px) {
    transition: transform 450ms, box-shadow 450ms !important;
    &:hover {
      box-shadow: 0 0.5em 2em ${offBlack}40;
      border: none;
    }
    &:not(:hover) {
      header {
        h1 {
          opacity: 0;
        }
        svg {
          display: block;
          font-size: 1.2em;
          color: ${secondaryText};
          margin-left: 0.2em;
        }
      }
      transform: translate(205px, 0);
      .expanded-content {
        display: none;
      }
      .phone-number,
      .call-timestamp,
      .alert-text,
      ${CallIconContainer} {
        opacity: 0;
      }
      .expander-space {
        transform: translate(-183px, 0);
        > div {
          display: block !important;
          .unmatched-call-alert-icon {
            transform: none;
          }
          > svg:not(.unmatched-call-alert-icon) {
            opacity: 0;
          }
        }
        > a {
          display: none !important;
        }
      }
    }
  }
`;

export default Container;
