import * as React from 'react';
import {
  Alert, EventFunction, severity, severityNames,
} from '.';
import Modal from '../Modal';
import { PrimaryButton, SecondaryButton } from '../buttons';

type Props = {
  modalAlert?: Alert,
  onClose: EventFunction,
};

export default function ErrorModal(
  {
    modalAlert,
    onClose,
  }: Props,
): React.ReactElement<any> {
  const onCloseHandler = (e: any) => {
    // If fatal this modal can't be closed.
    if (modalAlert && modalAlert.severity === severity.fatal) return;
    onClose(e);
  };

  return (
    <Modal
      open={!!modalAlert}
      zIndex={10000}
      onClose={onCloseHandler}
      title="Error"
      centered
      breadcrumbs="An error has occurred"
      okButtons={
        modalAlert && (
          <>
            {modalAlert.nextSteps
              && modalAlert.nextSteps.map(({ name, action }, i) => (
                <SecondaryButton
                  type="button"
                  key={i} // eslint-disable-line react/no-array-index-key
                  onClick={() => {
                    action();
                    onClose();
                  }}
                >
                  {name}
                </SecondaryButton>
              ))}
            {modalAlert.severity < severity.fatal && (
              <PrimaryButton type="button" onClick={onClose}>
                OK
              </PrimaryButton>
            )}
          </>
        )
      }
    >
      {modalAlert ? (
        <div
          className={`
          alert-box
          alert-box-${severityNames[modalAlert.severity]}
        `}
        >
          {modalAlert.message}
        </div>
      ) : null}
    </Modal>
  );
}
