import * as React from 'react';
import * as Alerts from '.';
import Modal from './modal';
import ToastRack from './toast-rack';

type Props = {};

type State = {
  modalAlert?: Alerts.Alert,
  toastAlerts: Alerts.Alert[]
};

// This is only really used to help us debug the alert system without having to generate real
// errors.
// (window as any).__alerts = Alerts;
// Go to the console and run __alerts.broadcast({ message: 'hello', severity: 1 })

export default class ErrorReporter extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { toastAlerts: [] };
    Alerts.addListener(this.update);
    Alerts.addClearListener(this.update);
  }

  update = () => {
    const alerts = Alerts.getCurrentAlerts();
    this.setState({
      modalAlert: alerts.find((a) => a.severity >= Alerts.severity.error),
      toastAlerts: alerts.filter((a) => a.severity < Alerts.severity.error),
    });
  };

  closeModal = () => {
    const { modalAlert } = this.state;
    if (!modalAlert) return;
    if (modalAlert.severity < Alerts.severity.pageFatal) Alerts.clear(modalAlert);
    // Redirect based on with app we are using.
    else {
      window.location.href = '/';
    }
  };

  render() {
    const { modalAlert, toastAlerts } = this.state;
    return (
      <>
        <Modal modalAlert={modalAlert} onClose={this.closeModal} />
        <ToastRack alerts={toastAlerts} />
      </>
    );
  }
}
