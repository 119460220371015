// Returns a phone number in E.164 format (or null if the input is garbage).
// Errs on the side of E.164 since there's currently no
// real downside to a false-positive between a tiny
// amount of wasted Dynamo storage.
export default function normalisePhoneNumber(raw: string): string | null {
  // Start out by stripping out obvious puncuation. Probably won't come up
  // but it's possible a journey field or similar might contain a
  // formatted number with spaces or whatever.
  /* eslint-disable-next-line no-irregular-whitespace */
  const result: string = raw.replace(/[-–—  .()]/g, '');

  // Anything that's just a + and then some digits is assumed to be as right as it's getting.
  if (/^\+\d{2,15}$/.test(result)) return result;

  // If it starts in a 0, assume it's a UK number and replace it with +44.
  if (/^0+\d{2,15}$/.test(result)) return `+44${result.replace(/^0+/, '')}`;

  // If it's a string of digits not starting in a 0, assume it's an
  // already-prefixed international number and simply add the +.
  if (/^\d{2,15}$/.test(result)) return `+${result}`;

  // We couldn't work out what this was supposed to be.
  return null;
}
