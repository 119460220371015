import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { encodeQuery } from 'common/utils/query-string';
import { Call } from 'common/types/call';
import api from 'common/api';
import log from 'logger';

import UnmatchedCallNotification from './UnmatchedCallNotification';
import CallDetailRow from './CallDetailRow';
import Container from './styled';

type State = {
  calls: Call[],
  loading: boolean,
  expanded: string | null,
  hidden?: boolean | null,
  notify: boolean,
};

function onLeadPage(): boolean {
  return /BOLead(Summary|Detail|Transactions)\.php/.test((window as any).location.href);
}

// This is the first date that calls will show for.
// It is equal to the start of yesterday.
function dateCutoff(): number {
  const cutoff = new Date();
  cutoff.setHours(0);
  cutoff.setMinutes(0);
  cutoff.setSeconds(0);
  cutoff.setMilliseconds(0);
  cutoff.setDate(cutoff.getDate() - 1);
  return cutoff.getTime();
}

export default class Calls extends React.Component<{}, State> {
  pollTimer?: number;

  constructor(props: {}, omitHiddenState?: boolean) {
    super(props);
    const state: State = {
      calls: [],
      loading: true,
      expanded: null,
      notify: false,
    };
    if (!omitHiddenState) state.hidden = true;
    this.state = state;
  }

  async componentDidMount() {
    if (!onLeadPage()) {
      this.setState({
        loading: false,
        hidden: true,
      });
    } else {
      this.setState({
        hidden: false,
      });
      this.load();
      this.pollTimer = window.setInterval(() => this.update(), 10000);
    }
  }

  componentWillUnmount() {
    if (this.pollTimer) clearInterval(this.pollTimer);
  }

  setHiddenState(hidden: boolean) {
    this.setState({ hidden });
  }

  async load() {
    this.setState({
      loading: true,
    });

    const calls: Call[] = [];
    const cutoff = dateCutoff();
    const allCalls: Call[] = await api.get<Call[]>(
      '/calls?mine=true&count=5&unmatched=false',
    );
    let notify = false;

    allCalls.forEach((call: Call) => {
      if (call.startedAt >= cutoff) {
        calls.push(call);
      } else if (!call.leadId && !call.matchConfirmed) {
        notify = true;
      }
    });

    if (!notify && allCalls.length === 5) {
      const lastCall = allCalls[4];
      const nextCall = await api.get<Call[]>(
        `/calls${
          encodeQuery({
            mine: 'true',
            count: '1',
            unmatched: 'true',
            before: (lastCall.startedAt - 1).toString(),
          })}`,
      );

      if (nextCall.length > 0) {
        notify = true;
      }
    }

    const expand = calls.find((call) => !call.endedAt);
    this.setState({
      loading: false,
      calls,
      notify,
      expanded: expand ? expand.callId : null,
    });
  }

  async update() {
    const { expanded, calls } = this.state;
    try {
      const [latestCall] = await api.get<Call[]>(
        '/calls?mine=true&count=1&unmatched=false',
      );

      if (!latestCall || latestCall.startedAt < dateCutoff()) return;
      if (calls.length > 0 && latestCall.callId === calls[0].callId) {
        this.setState({
          calls: [latestCall, ...calls.slice(1)],
        });
      } else {
        let { notify } = this.state;

        if (!notify && calls.length === 5) {
          notify = !calls[4].leadId && !calls[4].matchConfirmed;
        }

        this.setState({
          calls: [latestCall, ...calls.slice(0, 4)],
          expanded:
            expanded
            || (latestCall.endedAt ? null : latestCall.callId),
          notify,
        });
      }
    } catch (e: any) {
      log.error(`Error updating calls; ${e.message}`);
    }
  }

  render() {
    const {
      calls, notify, hidden, loading, expanded,
    } = this.state;

    if (hidden || (calls.length === 0 && !notify)) {
      return null;
    }

    return (
      <Container>
        <header>
          <FontAwesomeIcon icon={['far', 'phone']} />
          <h1>Your calls</h1>
          <a
            href={`/voice?user=mine&ref=${encodeURIComponent(
              (window as any).location.pathname + (window as any).location.search,
            )}`}
          >
            See all
          </a>
        </header>
        <ol className={loading ? 'loading' : ''}>
          {calls.map((call: Call) => (
            <CallDetailRow
              key={call.callId}
              call={call}
              expanded={expanded === call.callId}
              onExpand={() => this.setState({ expanded: call.callId })}
              onUpdate={() => this.load()}
            />
          ))}
          {notify && <UnmatchedCallNotification userHasCalls={calls.length > 0} />}
        </ol>
      </Container>
    );
  }
}
