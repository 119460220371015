import * as H from 'history';

type Props = {
  readonly location: H.Location,
  readonly history: H.History,
};

// returns a URLSearchParams. Pass in:
//    • a URL, to parse that
//    • the props value from a withRouter-wrapped component, to use its nav data
//    • null, to use the current browser URL
function usp(props: Props | null | string): URLSearchParams {
  if (typeof props === 'string') return new URLSearchParams(props);
  const { search } = props ? props.location : window.location;
  return new URLSearchParams(search);
}

// Returns a plain JS object for when you don't need a URLSearchParams
export function queryParams(props: Props | null | string): {
  [K in string]: string;
} {
  const q = usp(props);
  const output: any = {};
  Array.from(q.entries()).forEach(([k, v]) => {
    output[k] = v;
  });
  return output;
}

export function urlWithQueryParams(
  props: Props,
  values: {
    [K in string]: string | null;
  },
): string {
  const q = usp(props);
  Object.entries(values).forEach(([k, v]) => {
    if (v === null) q.delete(k);
    else q.set(k, v as any);
  });
  return `${(props || window).location.pathname}?${q.toString()}`;
}

export function assignQueryParams(
  props: Props,
  values: {
    [K in string]: string | null;
  },
): void {
  props.history.push(urlWithQueryParams(props, values));
}

export function encodeQuery(
  params: {
    [K in string]: string | void;
  },
): string {
  const q = new URLSearchParams();
  Object.entries(params).forEach(([k, v]) => {
    if (v !== undefined) q.set(k, v as any);
  });
  return `?${q.toString()}`;
}
