function hideWidget() {
  const { FreshworksWidget } = window as any;
  if (FreshworksWidget) FreshworksWidget('hide');
}

function showWidget() {
  const { FreshworksWidget } = window as any;
  if (FreshworksWidget) FreshworksWidget('show');
}

export default { hideWidget, showWidget };