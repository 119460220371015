import { IconDefinition, IconPrefix, IconName } from '@fortawesome/fontawesome-svg-core';

const width: number = 68;
const height: number = 55;
const ligatures: Array<any> = [];
const unicode: string = '';
const svgPathData: string = 'M66.8016 25.8019C67.0451 26.0454 67.1668 26.1671 67.1668 26.4106C67.1668 26.654 67.0451 26.8975 66.9234 27.141L64.4888 30.1842C64.2453 30.4277 64.0019 30.5494 63.7584 30.5494C63.5149 30.5494 63.2715 30.5494 63.1497 30.3059L61.3238 28.8452V52.9477C61.3238 53.5563 61.0803 54.0432 60.7152 54.4084C60.35 54.7736 59.863 54.8953 59.3761 54.8953H38.9255C38.6821 54.8953 38.4386 54.8953 38.1952 54.6519C37.9517 54.4084 37.9517 54.165 37.9517 53.9215V38.3401H30.161V53.9215C30.161 54.165 30.0393 54.4084 29.7958 54.6519C29.5524 54.8953 29.4306 54.8953 29.1872 54.8953H8.73659C8.12794 54.8953 7.64102 54.7736 7.27583 54.4084C6.91065 54.0432 6.78892 53.5563 6.78892 52.9477V28.8452L4.96297 30.3059C4.71951 30.5494 4.47605 30.5494 4.23259 30.5494C3.98913 30.5494 3.74567 30.4277 3.62394 30.1842L1.18935 27.141C0.945892 26.8975 0.945892 26.654 0.945892 26.4106C0.945892 26.1671 1.06762 26.0454 1.31108 25.8019L31.0131 1.45601C31.8652 0.725632 32.8391 0.360443 34.0564 0.360443C35.1519 0.360443 36.1258 0.725632 37.0996 1.45601L55.4808 16.4288V9.12498C55.4808 8.88152 55.4808 8.75979 55.7242 8.51633C55.9677 8.27287 56.2112 8.15114 56.4546 8.15114H60.35C60.5934 8.15114 60.7152 8.27287 60.9586 8.51633C61.2021 8.75979 61.3238 8.88152 61.3238 9.12498V21.2979L66.8016 25.8019ZM55.4808 49.0523V23.976L34.665 6.93384C34.1781 6.69039 33.8129 6.69039 33.4477 6.93384L12.6319 23.976V49.0523H24.318V33.4709C24.318 33.2274 24.318 33.1057 24.5614 32.8623C24.8049 32.6188 25.0484 32.4971 25.2918 32.4971H42.8209C43.0644 32.4971 43.1861 32.6188 43.4295 32.8623C43.673 33.1057 43.7947 33.2274 43.7947 33.4709V49.0523H55.4808Z';

const flgAddress: IconDefinition = {
  prefix: 'flg' as IconPrefix,
  iconName: 'address' as IconName,
  icon: [width, height, ligatures, unicode, svgPathData],
};

export default flgAddress;
