import React, { useLayoutEffect } from 'react';
import {
  Route, RouteProps,
} from 'react-router-dom';
// import log from 'logger';
// import authConfig from 'common/auth/Config';
import Sidebar from 'common/Sidebar';

import {
  hasValidJwt,
  login,
} from './Api';

const PrivateRoute = (props: RouteProps) => {
  useLayoutEffect(() => {
    if (!hasValidJwt()) {
      login();
    }
  });

  return (
    <>
    <Sidebar />
      <Route {...props} />
    </>
  );
};

export default PrivateRoute;
