import React from 'react';
import log from 'logger';
import { translateToClassicUrl } from './utils/classic-url-helper';
import { getWithExpiry, setWithExpiry } from './auth/Storage';

type Props = {
  children: React.ReactNode,
};

type State = {
  hasError: boolean,
  error: Error | null
};

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
    };
  }

  static getDerivedStateFromError(error: Error) {
    return {
      hasError: true,
      error: error,
    };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    log.error(`Error ${error} ${errorInfo}`);
  }

  render() {
    const { hasError, error } = this.state;
    const { children } = this.props;

    const chunkFailedMessage = /Loading [CSS ]*chunk [\d]+ failed./;

    if (error?.message && chunkFailedMessage.test(error.message)) {
      log.info('Handle chunk load failure');

      if (!getWithExpiry('chunk_failed')) {
        setWithExpiry('chunk_failed', 'true', 10000);
        window.location.reload();
      }
    }

    if (hasError && process.env.NODE_ENV !== 'development') {
      window.location.href = translateToClassicUrl('/bo/index.php?a=v3error');
    }

    return children;
  }
}

export default ErrorBoundary;
