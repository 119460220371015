import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

import { Call } from '../../../common/types/call';

export const CallIconContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.5em;
  position: relative;
  height: 28px;
  width: 28px;
  svg {
    height: 28px;
    width: 28px !important;
    position: absolute;
    top: -0.2em;
    left: 0;
  }
`;

// The following icons are just exported from Figma by selecting the elements and hitting
// "copy as SVG". I added a little spacing so the linter would be happy,
// and a transform to fix an export issue.

const outboundCallIcon = (
  <svg
    width="29"
    height="24"
    viewBox="0 0 29 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.31147 5.97458C1.08976 6.01153 0.941961 6.12238 0.794157 6.27019C0.683304 6.45494 0.609402 6.6397 0.609402 6.82445C0.609402 9.92834 1.38537 12.8105 2.93732 15.471C4.48926 18.0576 6.52156 20.1268 9.10813 21.6418C11.7686 23.1938 14.6508 23.9697 17.7547 23.9697C17.9764 23.9697 18.1611 23.9328 18.3089 23.785C18.4937 23.6741 18.6045 23.4894 18.6045 23.2677L19.4914 19.4247C19.5653 19.24 19.5283 19.0552 19.4175 18.8335C19.3436 18.6488 19.1958 18.501 18.9741 18.4271L14.8355 16.6534C14.6877 16.5795 14.503 16.5795 14.2813 16.6165C14.0965 16.6534 13.9487 16.7643 13.8009 16.9121L11.9903 19.1291C10.5492 18.464 9.25594 17.5772 8.14741 16.4317C7.03888 15.3232 6.1151 14.0299 5.44998 12.5888L7.66705 10.7413C7.81485 10.6304 7.9257 10.4826 7.96265 10.2978C8.03656 10.1131 7.9996 9.92834 7.9257 9.70663L6.15205 5.56812C6.07815 5.42031 5.9673 5.27251 5.74559 5.16166C5.56084 5.08776 5.37608 5.0508 5.15438 5.08776L1.31147 5.97458Z"
      fill="currentColor"
    />
    <path
      d="M18.7193 1.92768C18.6983 2.1757 18.7822 2.35608 18.908 2.49137C19.0548 2.6492 19.2435 2.71685 19.4323 2.6943L24.3604 2.58156L18.3418 9.05276C18.195 9.21059 18.1321 9.41352 18.1321 9.59391C18.1321 9.81938 18.216 9.99976 18.3418 10.1351L19.0129 10.8566C19.1597 11.0144 19.3274 11.1046 19.5162 11.0821C19.7049 11.1046 19.8727 11.0144 20.0195 10.8566L26.0381 4.38538L25.9333 9.6841C25.9333 9.90957 25.9962 10.1125 26.122 10.2478C26.2688 10.4056 26.4366 10.4958 26.6463 10.4507H27.569C27.7577 10.4733 27.9255 10.3831 28.0723 10.2252C28.1981 10.09 28.282 9.90957 28.282 9.6841V0.935576C28.282 0.755195 28.2191 0.552265 28.0723 0.394431C27.9465 0.259144 27.7577 0.191501 27.569 0.168954H19.4323C19.2435 0.191501 19.0548 0.259144 18.929 0.394431C18.7822 0.552265 18.7193 0.755195 18.7193 0.935576V1.92768Z"
      fill="currentColor"
      transform="translate(-5, 0)"
    />
  </svg>
);

const inboundCallIcon = (
  <svg
    width="25"
    height="26"
    viewBox="0 0 25 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.3791 7.45081C1.15739 7.48776 1.00959 7.59861 0.861784 7.74641C0.750931 7.93117 0.677029 8.11592 0.677029 8.30068C0.677029 11.4046 1.453 14.2867 3.00494 16.9472C4.55689 19.5338 6.58919 21.603 9.17576 23.118C11.8362 24.67 14.7184 25.446 17.8223 25.446C18.044 25.446 18.2288 25.409 18.3766 25.2612C18.5613 25.1503 18.6722 24.9656 18.6722 24.7439L19.559 20.901C19.6329 20.7162 19.5959 20.5315 19.4851 20.3098C19.4112 20.125 19.2634 19.9772 19.0417 19.9033L14.9032 18.1296C14.7554 18.0557 14.5706 18.0557 14.3489 18.0927C14.1641 18.1296 14.0163 18.2405 13.8685 18.3883L12.0579 20.6054C10.6169 19.9402 9.32357 19.0534 8.21504 17.9079C7.1065 16.7994 6.18273 15.5061 5.51761 14.065L7.73467 12.2175C7.88248 12.1066 7.99333 11.9588 8.03028 11.7741C8.10418 11.5893 8.06723 11.4046 7.99333 11.1829L6.21968 7.04435C6.14578 6.89654 6.03493 6.74874 5.81322 6.63788C5.62846 6.56398 5.44371 6.52703 5.222 6.56398L1.3791 7.45081Z"
      fill="currentColor"
    />
    <path
      d="M24.04 9.79391C24.061 9.54589 23.9771 9.3655 23.8513 9.23022C23.7045 9.07238 23.5157 9.00474 23.327 9.02729L18.3988 9.14003L24.4175 2.66883C24.5643 2.51099 24.6272 2.30806 24.6272 2.12768C24.6272 1.90221 24.5433 1.72182 24.4175 1.58654L23.7464 0.865009C23.5996 0.707175 23.4319 0.616984 23.2431 0.639532C23.0544 0.616984 22.8866 0.707175 22.7398 0.865009L16.7212 7.33621L16.826 2.03749C16.826 1.81201 16.7631 1.60908 16.6373 1.4738C16.4905 1.31596 16.3227 1.22577 16.113 1.27087H15.1903C15.0016 1.24832 14.8338 1.33851 14.687 1.49635C14.5612 1.63163 14.4773 1.81201 14.4773 2.03749V10.786C14.4773 10.9664 14.5402 11.1693 14.687 11.3272C14.8128 11.4624 15.0016 11.5301 15.1903 11.5526H23.327C23.5157 11.5301 23.7045 11.4624 23.8303 11.3272C23.9771 11.1693 24.04 10.9664 24.04 10.786V9.79391Z"
      fill="currentColor"
    />
  </svg>
);

export function callType(call: Call): string {
  // Although we can't detect it, agents can send voicemail so let's be explicit here.
  if (call.voicemailLeft) return 'Inbound voicemail';

  // Since outbound voicemail, ringing out while on hold, and so on all register as "answered" in
  // the telephony systems, don't try to guess whether the call was answered or not — just list it
  //  as "outbound".
  if (call.direction === 'outbound') return 'Outbound call';

  // If the call was inbound and answered then it will have an "answered by" row so we don't need to
  //  mark it "answered" here.
  if (call.answeredAt) return 'Inbound call';

  // The only thing left is a missed call. We can only detect these inbound, but again, they can
  // happen outbound so let's be explicit.
  return 'Missed inbound call';
}

export function CallIcon(
  {
    call,
    title,
  }: {
    call: Call,
    title?: string // should generally be set by CallIconWithTooltop (below)
  },
) {
  if (call.voicemailLeft) {
    return (
      <CallIconContainer title={title}>
        <FontAwesomeIcon icon={['far', 'voicemail']} />
      </CallIconContainer>
    );
  }

  return (
    <CallIconContainer title={title}>
      {call.direction === 'outbound' ? outboundCallIcon : inboundCallIcon}
    </CallIconContainer>
  );
}

export function CallIconWithTooltip(
  {
    call,
  }: {
    call: Call
  },
) {
  return <CallIcon call={call} title={callType(call)} />;
}
