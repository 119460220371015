import { IconDefinition, IconPrefix, IconName } from '@fortawesome/fontawesome-svg-core';

const width: number = 68;
const height: number = 69;
const ligatures: Array<any> = [];
const unicode: string = '';
const svgPathData: string = 'M0 6.18988C0 2.77131 2.7713 3.8147e-06 6.18987 3.8147e-06H27.7215C31.1401 3.8147e-06 33.9114 2.7713 33.9114 6.18988V27.7215C33.9114 31.1401 31.1401 33.9114 27.7215 33.9114H6.18987C2.7713 33.9114 0 31.1401 0 27.7215V6.18988ZM6.18987 6C6.08501 6 6 6.08501 6 6.18988V27.7215C6 27.8264 6.08501 27.9114 6.18987 27.9114H27.7215C27.8264 27.9114 27.9114 27.8264 27.9114 27.7215V6.18988C27.9114 6.08501 27.8264 6 27.7215 6H6.18987ZM23.6318 12.3301C24.8034 13.5016 24.8034 15.4011 23.6318 16.5727L17.9749 22.2296C16.8034 23.4011 14.9039 23.4011 13.7323 22.2296C13.7321 22.2293 13.7318 22.2291 13.7316 22.2288C13.5782 22.1244 13.4323 22.0041 13.2963 21.8681L11.175 19.7468C10.0034 18.5752 10.0034 16.6757 11.175 15.5041C12.3465 14.3326 14.246 14.3326 15.4176 15.5041L15.8163 15.9029L19.3892 12.3301C20.5607 11.1585 22.4602 11.1585 23.6318 12.3301ZM37.9688 17C37.9688 15.3431 39.3119 14 40.9688 14H64.9688C66.6256 14 67.9688 15.3431 67.9688 17C67.9688 18.6569 66.6256 20 64.9688 20H40.9688C39.3119 20 37.9688 18.6569 37.9688 17ZM0 41.2785C0 37.8599 2.7713 35.0886 6.18987 35.0886H27.7215C31.1401 35.0886 33.9114 37.8599 33.9114 41.2785V62.8101C33.9114 66.2287 31.1401 69 27.7215 69H6.18987C2.7713 69 0 66.2287 0 62.8101V41.2785ZM6.18987 41.0886C6.08501 41.0886 6 41.1736 6 41.2785V62.8101C6 62.915 6.08501 63 6.18987 63H27.7215C27.8264 63 27.9114 62.915 27.9114 62.8101V41.2785C27.9114 41.1736 27.8264 41.0886 27.7215 41.0886H6.18987ZM37.9688 52C37.9688 50.3431 39.3119 49 40.9688 49H64.9688C66.6256 49 67.9688 50.3431 67.9688 52C67.9688 53.6569 66.6256 55 64.9688 55H40.9688C39.3119 55 37.9688 53.6569 37.9688 52Z';

const flgChecklist: IconDefinition = {
  prefix: 'flg' as IconPrefix,
  iconName: 'checklist' as IconName,
  icon: [width, height, ligatures, unicode, svgPathData],
};

export default flgChecklist;
