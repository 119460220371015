import * as React from 'react';
import { Header, Breadcrumb, Controls } from './styled';
import { BackLink } from './return-nav';
import { BreadcrumbProp } from './types';

export default function HeaderComponent(
  props: {
    // A string, or a component to render in place of the text
    title: string | React.ReactNode,
    // Either a { url, title } pair, or some text to display in place of it
    defaultBreadcrumb?: BreadcrumbProp,
    // Used by modals &c to prevent the regular page navigation overriding your popup titles
    alwaysUseDefaultBreadcrumb?: boolean,
    // Normal React children
    children?: React.ReactNode,
    icon?: React.ReactNode,
  },
): React.ReactElement {
  const {
    defaultBreadcrumb,
    alwaysUseDefaultBreadcrumb,
    title,
    children,
    icon,
  } = props;
  return (
    <Header className="header-bar">
      <Breadcrumb>
        <BackLink
          def={defaultBreadcrumb}
          alwaysUseDefault={alwaysUseDefaultBreadcrumb}
        />
      </Breadcrumb>
      {icon ? icon : null}
      <h1>{title}</h1>
      {children ? <Controls>{children}</Controls> : null}
    </Header>
  );
}
