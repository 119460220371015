import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

import permissions from 'shared/permissions';
import { focusRingAndKeyboardOnly } from '../../../common/form-elements/inputs';
import LeadLink, { LeadLinkContainer } from '../../../common/LeadLink';
import { hasPermission } from '../../../common/auth';
import { Call } from '../../../common/types/call';
import {
  dangerRed,
  flgGreen,
  focusOrange,
  borderRadius,
  secondaryText,
  background,
} from '../../../common/theme';

type Props = {
  call: Call,
  match?: Function,
  unmatch?: ((arg0: boolean) => any),
  currentLead?: string,
  expanded: boolean,
  allowAmber: boolean
};

// Keep this out of the main styled.js as this component is likely to move:
// TODO: put these buttons in the common buttons file?
const Container = styled.div`
  &.unmatched { background: ${dangerRed}; }
  &.matched { background: ${flgGreen}; }
  &.matched-elsewhere { background: ${focusOrange}; }
  color: white;
  * { color: white !important; }
  &.archived {
    background: ${background};
    color: ${secondaryText};
    * { color: ${secondaryText} !important; }
    button {
      &:hover { border-color: ${secondaryText} !important; }
      border-color: ${secondaryText}88 !important;
    }
  }
  border-radius: 4px;
  padding: 0.25em 0.65em;
  > svg {
    font-size: 1.25em;
    width: 0.8em !important;
    transform: translate(0, 0.05em);
    margin-right: 0.4em;
  }
  font-weight: normal;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, Helvetica, Arial, sans-serif;
  line-height: 2em;
  div {
    padding-left: 1.5em;
    margin: -0.2em 0 0.3em 0;
    &.lead { margin-top: -0.75em; }
    button {
      ${focusRingAndKeyboardOnly}
      border-radius: ${borderRadius};
      background: transparent;
      color: inherit;
      font-size: inherit;
      padding: 0.25em 0.5em;
      border: 1px solid #fff8;
      margin-right: 0.25em;
      svg {
        margin-right: 0.5em;
        font-size: 0.8em;
        transform: translate(0, -0.1em);
      }
      &:hover { border-color: white; }
      cursor: pointer;
    }
  }
  ${LeadLinkContainer}:not(:hover) {
    border-color: white;
  }
  button:focus, button.focus-visible[data-focus-visible-added] {
    box-shadow: 0 0 0 4px white; }
  a:focus, a.focus-visible[data-focus-visible-added] {
    outline-color: white; }
`;

export default function MatchStatusCard(
  {
    call,
    currentLead,
    match,
    unmatch,
    expanded,
    allowAmber,
  }: Props,
) {

  function IfExpanded({ children }: any) {
    return expanded ? children : null;
  }

  if (!call.leadId && call.matchConfirmed) {
    return (
      <Container className="archived">
        <FontAwesomeIcon icon={['far', 'phone-slash']} />
        <IfExpanded>
          <>
            Archived
            <div className="buttons">
              {unmatch && (
              <button type="button" onClick={() => unmatch(false)}>
                <FontAwesomeIcon icon={['far', 'undo']} />
                Restore
              </button>
              )}
            </div>
          </>
        </IfExpanded>
      </Container>
    );
  }

  if (!call.leadId) {
    return (
      <Container className="unmatched">
        <FontAwesomeIcon icon={['far', 'unlink']} />
        <IfExpanded>
          <>
            Not matched
            <div className="buttons">
              {hasPermission(permissions.viewLeads) && (
              <button type="button" onClick={match as any}>
                <FontAwesomeIcon icon={['far', 'link']} />
                Match
              </button>
              )}
              {unmatch && (
              <button type="button" onClick={() => unmatch(true)}>
                <FontAwesomeIcon icon={['far', 'phone-slash']} />
                Archive
              </button>
              )}
            </div>
          </>
        </IfExpanded>
      </Container>
    );
  }

  if (currentLead && call.leadId === currentLead) {
    return (
      <Container className="matched">
        <FontAwesomeIcon icon={['far', 'link']} />
        <IfExpanded>
          <>
            Matched
            <div className="buttons">
              {unmatch && (
              <button type="button" onClick={() => unmatch(false)}>
                <FontAwesomeIcon icon={['far', 'unlink']} />
                Unmatch
              </button>
              )}
              {unmatch && (
              <button type="button" onClick={() => unmatch(true)}>
                <FontAwesomeIcon icon={['far', 'phone-slash']} />
                Archive
              </button>
              )}
            </div>
          </>
        </IfExpanded>
      </Container>
    );
  }

  return (
    <Container className={allowAmber ? 'matched-elsewhere' : 'matched'}>
      <FontAwesomeIcon icon={['far', 'link']} />
      <IfExpanded>
        <>
          Matched to
          <div className="lead">
            <LeadLink leadId={call.leadId} displayName />
          </div>
          <div className="buttons">
            <button type="button" onClick={match as any}>
              <FontAwesomeIcon icon={['far', 'link']} />
              {currentLead ? 'Match to current lead' : 'Rematch'}
            </button>
          </div>
        </>
      </IfExpanded>
    </Container>
  );
}
