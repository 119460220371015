import * as React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { DataType, DefaultInputStyle } from '../types/field';
import { iconFromDataType } from '../helpers';
import { HeaderIconContainer } from '../containers';
import { SecondaryButton } from './base';

export { HelpButtonBase, HelpButton } from './help-button';
export { InlineTextButton, ButtonishA, ButtonishLink } from './links';
export {
  base,
  IconButton,
  PrimaryButton,
  SecondaryButton,
  TertiaryButton,
  TertiaryIconButton,
  DangerButton,
} from './base';

const compact = `
  height: 2.5em;
  padding: 0 1em;
`;

export const CompactButton = styled(SecondaryButton)`
  ${compact}
  text-align: left;
`;

export function PreIcon(
  {
    icon,
  }: {
    icon: IconName
  },
): React.ReactElement<'svg'> {
  return <FontAwesomeIcon className="left" icon={['far', icon]} />;
}

export function PostIcon(
  {
    icon,
  }: {
    icon: IconName
  },
): React.ReactElement<'svg'> {
  return <FontAwesomeIcon className="right" icon={['far', icon]} />;
}

export function HeaderIcon(
  {
    dataType,
    defaultInputStyle,
  }: {
    dataType: DataType | null,
    defaultInputStyle?: DefaultInputStyle
  },
): React.ReactElement<'div'> | null {
  if (!dataType) return null;
  const { icon, color } = iconFromDataType(dataType, defaultInputStyle);
  return (
    <HeaderIconContainer background={color}>
      <FontAwesomeIcon icon={icon} color="white" />
    </HeaderIconContainer>
  );
}

export const DoubleButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    display: block;
  }
`;

export const RightButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  button {
    display: block;
  }
`;
