import { format } from 'date-fns';
import log from 'logger';

const predefinedFormats = {
  justTime: 'HH:mm',
  minutesHoursAndMeridiem: 'h:mma',
  hoursAndMeridiem: 'ha',
  justDate: 'do MMMM yyyy',
  simpleDate: 'dd/MM/yyyy',
  simpleDateTime: 'dd/MM/yyyy HH:mm',
  verbose: 'EEEE do MMMM yyyy, HH:mm',
};

// Expects time in format 14:00
export function parseTimeStringToDate(time: string): Date {
  const date = new Date();
  date.setHours(Number(time.substr(0, 2)));
  date.setMinutes(Number(time.substr(3, 2)));
  date.setSeconds(0);
  return date;
}

export function formatDate(
  date: Date | number | string,
  formatString?: string,
): string {
  const theFormat = formatString || predefinedFormats.verbose;
  return format(date instanceof Date ? date : new Date(date), theFormat);
}

export function justTime(date: Date | number | string): string {
  return formatDate(date, predefinedFormats.justTime);
}

export function justDate(date: Date | number | string): string {
  return formatDate(date, predefinedFormats.justDate);
}

export function simpleDate(date: Date) {
  return formatDate(date, predefinedFormats.simpleDate);
}

export function simpleDateTime(date: Date) {
  return formatDate(date, predefinedFormats.simpleDateTime);
}

export function verboseDateTime(date: Date) {
  return formatDate(date, predefinedFormats.verbose);
}

// Parses a string in the format dd/mm/yyyy
export function parseSimpleDate(date?: string | null): Date {
  if (date && typeof date === 'string') {
    const parts = date.split('/');
    if (date.length === 10 && parts.length === 3) {
      const newDate = new Date(Number(parts[2]), Number(parts[1]) - 1, Number(parts[0]), 12, 0, 0);
      // Ensures that the user hasn't done something like 33/19/2019
      try {
        if (date === simpleDate(newDate)) return newDate;
      } catch {
      }
    }
  }
  return new Date(NaN);
}

// Parses a string in the format dd/mm/yyyy hh:mm
export function parseSimpleDateTime(date?: string | null): Date {
  if (date && typeof date === 'string') {
    // Parse the date part of the string
    const datePart = parseSimpleDate(date.slice(0, 10));
    if (!Number.isNaN(datePart.getTime())) {
      // Get the time part of the string.
      const timeParts = date.slice(date.length - 5).split(':');
      if (timeParts.length === 2) {
        datePart.setHours(Number(timeParts[0]));
        datePart.setMinutes(Number(timeParts[1]));
        // Ensures that the user hasn't done something like 33/19/2019 99:99
        try {
          if (date === simpleDateTime(datePart)) return datePart;
        } catch {
        }
      }
    }
  }
  return new Date(NaN);
}

// Parses a string in the format hh:mm, returns number of seconds
export function parseSimpleTime(time?: string | null): number {
  if (time && (time.length === 5 || time.length === 8)) {
    const timeParts = time.split(':');
    if (timeParts.length >= 2) {
      if (timeParts[0].indexOf('.') !== -1 || timeParts[1].indexOf('.') !== -1  || timeParts[0].indexOf('-') !== -1  || timeParts[1].indexOf('-') !== -1 ){
        return NaN;
      }
      const hours = Number(timeParts[0]);
      const minutes = Number(timeParts[1]);
      if (hours < 24 && minutes < 60) {
        let number = (hours * 3600) + (minutes * 60);
        if (timeParts[2]) {
          if (timeParts[2].indexOf('.') !== -1  || timeParts[2].indexOf('-') !== -1 ){
            return NaN;
          }
          number += Number(timeParts[2]);
        }
        return number;
      }
    }
  }
  return NaN;
}

export function hoursMinutesFromMinutes(totalMinutes: number) {
  const minutes = totalMinutes % 60; // Get the minutes
  const hours = (totalMinutes - minutes) / 60; // Get the hours.
  return { minutes, hours };
}

export function hoursAndMinutesText(totalMinutes: number) {
  const hoursAndMinutes = hoursMinutesFromMinutes(totalMinutes);

  let minuteText = '';
  let hourText = '';

  // Minutes
  if (hoursAndMinutes.minutes === 1) minuteText = `${hoursAndMinutes.minutes} minute`;
  if (hoursAndMinutes.minutes > 1) minuteText = `${hoursAndMinutes.minutes} minutes`;

  // Hours
  if (hoursAndMinutes.hours === 1) hourText = `${hoursAndMinutes.hours} hour`;
  if (hoursAndMinutes.hours > 1) hourText = `${hoursAndMinutes.hours} hours`;

  // All together now
  if (minuteText.length && hourText.length) return `${hourText} ${minuteText}`;
  if (minuteText.length) return minuteText;
  if (hourText.length) return hourText;

  return '';
}

export function validateISODate(dateString: string) {
  try {
    const date = new Date(dateString);
    return dateString === date.toISOString();
  } catch (error) {
    log.warn(`Could not parse date ${dateString}`, error);
  }
}
