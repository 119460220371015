import * as React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { emptyListPlaceholder, largeFontSize } from './theme';

export const Container = styled.div<{ margin: string }>`
  left: 0;
  width: 100%;
  text-align: center;
  font-size: ${largeFontSize};
  svg {
    width: 100% !important;
    height: 3em !important;
    display: block;
    margin-bottom: 1em;
  }
  color: ${emptyListPlaceholder};

  &.filling {
    position: absolute;
    top: calc(50% - 4.125em);
  }

  &.inline {
    margin: ${props => props.margin} 0;
  }
`;

export default function EmptyListPlaceholder({
  icon,
  children,
  inline,
  margin = '5em',
}: {
  icon?: string;
  children?: React.ReactNode;
  inline?: boolean;
  margin?: string;
}): React.ReactElement<'div'> {
  return (
    <Container className={inline ? 'inline' : 'filling'} margin={margin}>
      <FontAwesomeIcon icon={['far', (icon as IconName) || 'magic']} />
      {children}
    </Container>
  );
}
