/*
    To turn logging on, type FLG.turnLoggingOn() in the console.
    Type FLG.turnLoggingOff() in the console to turn it off.
*/
import { turnLoggingOn, turnLoggingOff } from 'logger';

// Add global config options that you want to be exposed to the console here:
type FLG = {
  turnLoggingOn: () => void,
  turnLoggingOff: () => void,
};

declare global {
  interface Window { FLG: FLG}
}

const config: FLG = {
  turnLoggingOn,
  turnLoggingOff,
};

window.FLG = config;

export default window.FLG;
