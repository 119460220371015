import { Call } from '../../../common/types/call';
import * as Alerts from '../../../common/Alerts';
import api from '../../../common/api';

export default async function unmatch(call: Call, archive: boolean, update: any): Promise<void> {
  // Get the current values
  const { leadId, matchConfirmed } = call;
  // If this is a no-op, just return.
  if (!leadId && matchConfirmed === archive) return;
  // Update the server-side records.
  await api.patch(`/calls/${call.callId}`, {
    leadId: null,
    matchConfirmed: archive,
  }).then(update);
  // Display an 'undo' toast if we unmatched the lead — restoring from the archive is one click
  // anyway but you can't undo the unmatching unless you happen to have memorised the
  // previously-matched lead ID.
  if (leadId) {
    Alerts.broadcast({
      message: `Call unmatched from lead ${leadId.toString()}.`,
      severity: Alerts.severity.info as Alerts.AlertSeverityType,
      nextSteps: [
        {
          name: 'Undo',
          action: () => api.patch(`/calls/${call.callId}`, {
            leadId,
            matchConfirmed: false,
          }).then(update),
        },
      ],
    });
  }
}
