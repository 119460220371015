import React from 'react';
import AlertComponent from 'common/Alerts/component';
import CallWidget from './CallWidget';

export default function OverlayApp({
  showCallWidget,
}: {
  showCallWidget: boolean
}) {
  return (
    <>
      <AlertComponent />
      {
        showCallWidget ? <CallWidget /> : null
      }
    </>
  );
}
