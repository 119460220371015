import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { focusRingAndKeyboardOnly, focusRing } from '../form-elements/inputs';
import { flgPurple, borderRadius } from '../theme';

export const InlineTextButton = styled.button`
  display: inline;
  background: none;
  border: none;
  border-radius: ${borderRadius};
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  color: ${flgPurple};
  padding: 0;
  text-decoration: underline;
  cursor: pointer;
  ${focusRing}

  &:not([disabled]):hover {
    color: ${flgPurple};
    text-decoration: none;
  }
  &:disabled {
    opacity: 0.5;
  }
  svg {
    display: inline-block;
    width: 1em !important;
    height: 1em !important;
    margin-right: 1em;
  }
  span {
    margin-left: 2em;
  }
  svg + span {
    margin-left: 0;
  }
`;

export const ButtonishA = styled.a`
  ${focusRingAndKeyboardOnly}
  color: inherit;
  &:hover svg,
  &:active svg,
  &:visited svg {
    color: inherit;
  }
  &:not(:disabled):hover {
    opacity: 0.6;
  }
  display: inline-block;
  border-radius: ${borderRadius};
  line-height: 2em;
  display: inline-block;
  text-align: center;
  width: 2em;
  height: 2em;
`;

export const ButtonishLink = styled(Link)`
  ${focusRingAndKeyboardOnly}
  color: inherit;
  &:hover svg,
  &:active svg,
  &:visited svg {
    color: inherit;
  }
  &:not(:disabled):hover {
    opacity: 0.6;
  }
  display: inline-block;
  border-radius: ${borderRadius};
  line-height: 2em;
  display: inline-block;
  text-align: center;
  width: 2em;
  height: 2em;
`;
