import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/pro-regular-svg-icons';

export default function UnmatchedCallNotification(
  {
    userHasCalls,
  }: {
    userHasCalls: boolean
  },
) {
  return (
    <li className="unmatched-call-alert">
      <a
        href={`/voice?user=mine&unmatched=true&ref=${encodeURIComponent(
          window.location.pathname + window.location.search,
        )}`}
      >
        <span className="alert-text">
          You have
          {userHasCalls ? ' older ' : ' '}
          unmatched calls.
        </span>
        <div className="expander-space">
          <div>
            <FontAwesomeIcon
              className="unmatched-call-alert-icon"
              icon={faCircle}
            />
          </div>
        </div>
      </a>
    </li>
  );
}
