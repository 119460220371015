/* eslint-disable react/no-array-index-key */
import React, { ReactNode, Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressCard } from '@fortawesome/pro-regular-svg-icons';
import styled from 'styled-components';

import permissions from 'shared/permissions';
import { leadUrl } from './utils/classic-url-helper';
import { hasPermission } from './auth';
import { flgPurple } from './theme';
import { Lead } from './types/lead';
import api from './api';

type Props = {
  leadId: string,
  displayName: boolean,
  hideIcon?: boolean,
  inlineId?: boolean,
  addWordLead?: boolean,
  noLink?: boolean,
  capitalise?: boolean
};

type State = {
  lead: Lead | null
};

export const LeadLinkContainer = styled.a`
  text-decoration: none;
  border-bottom: 1px solid ${flgPurple};
  &:hover {
    border-bottom: 1px solid transparent;
  }
  svg {
    font-size: 0.95em;
    margin-right: 0.5em;
  }
`;

export default class LeadLink extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { lead: null };
  }

  async componentDidMount() {
    const { displayName, leadId } = this.props;
    if (
      displayName
      && hasPermission(permissions.viewLeads)
    ) {
      this.setState({
        lead: await api.getWithCache(`/leads/${leadId}`).catch(() => null) as Lead | null,
      });
    }
  }

  render() {
    const { lead } = this.state;
    const {
      leadId,
      displayName,
      hideIcon,
      inlineId,
      noLink,
      addWordLead,
      capitalise,
    } = this.props;
    const children: React.ReactNode[] = [];

    if (!hideIcon) children.push(<FontAwesomeIcon icon={faAddressCard} />);

    if (displayName && lead) {
      children.push(
        `
        ${lead!.values!.title || ''}
        ${lead!.values!.firstname || ''}
        ${lead!.values!.lastname || ''}
        ${inlineId ? `(${leadId})` : ''}
      `.trim(),
      );
    } else {
      if (addWordLead) children.push(capitalise ? 'Lead ' : 'lead ');
      children.push(leadId);
    }

    // Don't show a tooltip if the leadId is inline.
    const title = displayName && !inlineId && lead ? `Lead ${leadId}` : undefined;

    if (noLink) return <span title={title}>{children}</span>;

    return (
      <LeadLinkContainer
        href={noLink ? undefined : leadUrl(leadId)}
        target="_blank"
        title={title}
      >
        {children.map(
          (child: ReactNode, index: number) => <Fragment key={index}>{child}</Fragment>,
        )}
      </LeadLinkContainer>
    );
  }
}
