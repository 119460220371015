import * as React from 'react';
import * as H from 'history';
import { RouteComponentProps, Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import log from 'logger';
import { queryParams } from '../utils/query-string';
import { BreadcrumbProp } from './types';
import backLinkDetails from './back-link-details';

const history: string[] = [];
let prefix: string = '';
let currentBackNavigation: string | null = null;
// Expose this for debug purposes
// window.__history = history;

export function getPrefix(): string {
  return prefix;
}

export function setPrefix(newPrefix: string) {
  prefix = newPrefix;
}

// Initialise the history array with the ref parameter if there is one
const q = queryParams(null);
if (q.ref) {
  const p = q.ref;
  // Don't accept external links (for security)
  if (p[0] === '/' && p[1] !== '/') history.push(p);
}

export function getReturnLink(): string | null {
  return history[history.length - 1] || null;
}

function locationsMatch(a: H.Location, b: H.Location): boolean {
  if (a.pathname !== b.pathname) return false;
  if (a.search !== b.search) return false;
  return true;
}

// Just takes a location object from router props and makes it into a historyable URL fragment
function urlPart(
  l: {
    pathname: string,
    search: string
  },
): string {
  return prefix + l.pathname + l.search;
}

/* Prevent a navigation from being pushed onto the history stack.
   Call this either in the onClick handler of a link (as above) or before a redirect,
   and we will expect the movement and ignore it for the purposes of generating a back link.
*/
export function ignoreNavigation(url: string) {
  currentBackNavigation = url;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
class _HistoryMonitor extends React.Component<RouteComponentProps> {
  // TODO: This method is deprecated and shouldn't be used - https://reactjs.org/blog/2018/03/27/update-on-async-rendering.html.  I've updated it to use the correct "UNSAFE" name for now until we can rework this component.x
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps: { location: H.Location }) {
    const { location } = this.props;
    // If this is the first render then stop here — until we leave a page it isn't history.
    if (!this.props || !location) return;
    // If we haven't moved and just some other props update has happened, ignore this change.
    if (locationsMatch(location, nextProps.location)) return;

    /* If we're changing URL, but the new URL doesn't differs significantly from the old one,
       also stop — we don't want to clutter the history with boring things. For now at least,
       we assume that a 'significantly' different page is one with a different URL rather than
       simply different query parameters. Some parts of the app might need their own special-casing
       here.
    */
    const oldLocation = location;
    const newLocation = nextProps.location;
    if (
      oldLocation.pathname.replace(/\/$/, '')
      === newLocation.pathname.replace(/\/$/, '')
    ) return;

    /* If we're clicking the back link itself then we never want to push that onto the stack
       — almost certainly we're popping a level off it.
    */
    if (currentBackNavigation) {
      const lastUrl = history.pop();
      if (lastUrl && lastUrl !== currentBackNavigation) {
        log.warn(
          `Clicked 'back' link to ${currentBackNavigation}, but the last URL on our history stack is ${lastUrl}.`,
        );
        history.push(lastUrl);
      }
      currentBackNavigation = null;
      return;
    }

    // We're making a loggable move — this either means we're going forwards and pushing new
    // history onto the stack, or backwards and popping something off. It's an easy enough check:
    const lastUrl = history[history.length - 1];
    if (urlPart(newLocation) === lastUrl) history.pop();
    else history.push(urlPart(oldLocation));
  }

  // Emit no HTML
  render() {
    return null;
  }
}
export const HistoryMonitor = withRouter(_HistoryMonitor);

export function BackLink(
  params: {
    def?: BreadcrumbProp,
    alwaysUseDefault?: boolean,
  },
): React.ReactElement {
  const ref = backLinkDetails(getReturnLink(), prefix, params);

  if (!ref) {
    return <></>;
  }

  const {
    url,
    title,
    external,
  } = ref;

  if (!url) {
    return title ? <>{title}</> : <></>;
  }

  return external ? (
    // Url is left alone for external links.
    <a href={url}>
      <span>{title}</span>
    </a>
  ) : (
    // Prefix is taken off in backLinkDetails for interal links
    // because Router basename adds it back on. It's added back on
    // for ignoreNavigation so that it matches the history stack
    // and allows things to be popped off.
    <Link to={url} onClick={() => ignoreNavigation(prefix + url)}>
      <span>{title}</span>
    </Link>
  );
}
