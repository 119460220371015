import styled from 'styled-components';
import {
  flgBlue,
  dangerRed,
  secondaryBorder,
  hoverBlue,
  formLightBlue,
  formDisabledGrey,
  highlightRed,
  formMidGrey,
} from '../theme';
import {
  focusRingAndKeyboardOnly,
  base as inputBase,
} from '../form-elements/inputs';

export const base = `
  ${inputBase}
  ${focusRingAndKeyboardOnly}
  border: none;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
  margin: 0em;
  & + button {
    margin-left: 0.5em;
  }
  svg.right {
    margin: 0 0 0 0.5em;
  }
  svg.left {
    margin: 0 0.5em 0 0;
  }
  padding: 0.75em 1.5em;
  line-height: 1;
  background: unset;
  &:disabled {
    cursor: default;
    color: ${formMidGrey};
  }
`;

export const IconButton = styled.button`
  ${inputBase}
  ${focusRingAndKeyboardOnly}
  cursor: pointer;
  color: inherit;
  width: 2em;
  height: 2em;
  &:not(:disabled):hover {
    opacity: 0.6;
  }
  display: inline-block;
  border: none;
  background: none;
  svg {
    margin: 0;
  }
  padding: 0;
  &:disabled {
    background: none;
  }
`;

export const PrimaryButtonBase = `
  ${base}
  color: white;
  background: ${flgBlue};
  &:not(:disabled):hover,
  &:not(:disabled):focus {
    background: ${hoverBlue};
  }
`;

export const PrimaryButton = styled.button`
  ${PrimaryButtonBase}
`;

export const SecondaryButton = styled.button`
  ${base}
  border: 1px solid ${secondaryBorder};
  color: ${flgBlue};
  background: white;
  &:hover,
  &:focus {
    background-color: ${formLightBlue};
    border-color: ${flgBlue};
  }
  &:disabled {
    background-color: white;
    border-color: ${formDisabledGrey};
  }
`;

export const TertiaryButtonBase = `
  color: ${flgBlue};
  border: none;
  &:hover,
  &:focus {
    background-color: ${formLightBlue};
  }
  &:disabled {
    background-color: white;
  }
`;

export const TertiaryIconButton = styled(IconButton)`
  ${TertiaryButtonBase}
  border-radius: 50%;

  & svg {
    margin: 0;
  }
`;

export const TertiaryButton = styled.button`
  ${base}
  ${TertiaryButtonBase}
`;

export const DangerButton = styled.button`
  ${base}
  color: white;
  background-color: ${dangerRed};
  &:not(:disabled):hover,
  &:not(:disabled):focus {
    background-color: ${highlightRed};
  }
`;
