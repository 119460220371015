import React from 'react';
import log from 'logger';

export type EventFunction = (event?: any) => void;
export type AlertSeverityType = 0 | 1 | 2 | 3 | 4 | 5;
export type Alert = {
  message: string | React.ReactElement<any>,
  // Don't use the numbers directly, use the 'severity' object,
  // that way you can say things like
  // 'if (alert.severity >= severity.warning)'
  // plus we get to change the numbers without breaking anything:
  severity: AlertSeverityType,
  // Put whatever you like in here, we won't look at it but we will log it.
  details?: any,
  // Any actions the user can take in response to this alert.
  // These currently display as buttons on toasts etc.
  nextSteps?: Array<{
    name: string,
    action: EventFunction,
  }>,
  hideAfter?: number // Override the default timeout
};

export type Listener = ((alert: Alert) => void);

export const severity: { [key: string]: AlertSeverityType } = {
  fatal: 5, // For unrecoverable errors, perhaps we show an unclearable box
  pageFatal: 4, // For unrecoverable errors we can escape by navigating away
  error: 3, // For serious errors, perhaps we show a clearable box
  warning: 2, // For minor errors, perhaps we show a red toast
  info: 1, // For non-error alerts, perhaps we show a regular toast
  success: 0, // For actions like successfully adding a new field to the end of a journey
};

export const severityNames: string[] = [];
Object
  .entries(severity)
  .forEach(([name, number]: [string, number]) => { severityNames[number] = name; });

let currentAlerts: Alert[] = [];
let listeners: Listener[] = [];
let clearListeners: Listener[] = [];

export function clear(alert: Alert): void {
  currentAlerts = currentAlerts.filter((a) => a !== alert);
  clearListeners.forEach((listener: Listener) => listener(alert));
}

export function broadcast(alert: Alert, clearImmediately: boolean = false): void {
  if (alert.severity > severity.warning) log.error(alert);
  else if (alert.severity < severity.error) log.warn(alert);
  else log.error(alert);

  currentAlerts.push(alert);
  listeners.forEach((listener: Listener) => listener(alert));
  if (clearImmediately) clear(alert);
}

export function addListener(listener: Listener, hearCurrent: boolean = false): void {
  listeners.push(listener);
  if (hearCurrent) currentAlerts.forEach((alert: Alert) => listener(alert));
}

export function removeListener(listener: Listener): void {
  listeners = listeners.filter((l) => l !== listener);
}

export function addClearListener(listener: Listener): void {
  clearListeners.push(listener);
}

export function removeClearListener(listener: Listener): void {
  clearListeners = clearListeners.filter((l) => l !== listener);
}

export function getCurrentAlerts(): Alert[] {
  return currentAlerts;
}
