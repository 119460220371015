import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { PastDate } from '../../../common/RelativeDate';
import { Call } from '../../../common/types/call';
import api from '../../../common/api';

import { CallIconWithTooltip } from '../../Voice/CallLog/CallIcon';
import MatchStatusCard from '../../Voice/Call/MatchStatusCard';
import PhoneNumber from '../../../common/PhoneNumber';
import LeadLink from '../../../common/LeadLink';
import { queryParameters } from '../QueryUtil';
import unmatch from '../../Voice/Call/unmatch';

function LeadNameOrPhoneNumber(
  {
    call,
  }: {
    call: Call
  },
) {
  if (call.leadId) {
    return (
      <LeadLink
        leadId={call.leadId}
        key={call.leadId}
        capitalise
        displayName
        hideIcon
        addWordLead
        noLink
      />
    );
  }
  if (call.customerNumber) return <PhoneNumber number={call.customerNumber} />;
  return (
    <>
      &apos;Unknown number&apos;
    </>
  );
}

export default function CallDetailRow(
  {
    call,
    expanded,
    onExpand,
    onUpdate,
  }: {
    call: Call,
    expanded: boolean,
    onExpand: Function,
    onUpdate: (call: Call) => any,
  },
) {
  const currentLead = queryParameters().intLeadID;
  const archived = !call.leadId && call.matchConfirmed;

  return (
    <li
      className={`
    ${archived ? 'archived-call' : ''}
    ${expanded ? 'expanded-call' : ''}
  `}
    >
      <div
        onClick={() => {
          if (!expanded) onExpand();
        }}
        onKeyPress={(e: any) => {
          if (!expanded && e.keycode !== 13) {
            onExpand();
            setTimeout(() => {
              const link = document.getElementById(
                `${call.callId}-details-link`,
              );
              if (link) link.focus();
            });
          }
        }}
        tabIndex={expanded ? -1 : 0}
        role="button"
      >
        <CallIconWithTooltip call={call} />
        <span className="phone-number">
          <LeadNameOrPhoneNumber call={call} />
        </span>
        <span className="call-timestamp">
          {call.endedAt ? (
            <PastDate date={call.startedAt} capitalise />
          ) : (
            'Ongoing'
          )}
        </span>
        <div className="expander-space">
          <a
            id={`${call.callId}-details-link`}
            href={`/voice/call/${call.callId}?ref=${encodeURIComponent(
              window.location.pathname + window.location.search,
            )}`}
          >
            Details
          </a>
          <div>
            <MatchStatusCard
              call={call}
              expanded={false}
              allowAmber={!call.endedAt}
              currentLead={currentLead}
            />
            <FontAwesomeIcon icon={['fas', 'caret-down']} />
          </div>
        </div>
        {expanded ? (
          <div className="expanded-content">
            <MatchStatusCard
              call={call}
              expanded
              allowAmber
              currentLead={currentLead}
              match={() => api.patch(`/calls/${call.callId}`, {
                leadId: currentLead,
              }).then(onUpdate as any)}
              unmatch={(archive: boolean) => unmatch(call, archive, onUpdate)}
            />
          </div>
        ) : null}
      </div>
    </li>
  );
}
