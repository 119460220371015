import { IconDefinition, IconPrefix, IconName } from '@fortawesome/fontawesome-svg-core';

const width: number = 81;
const height: number = 60;
const ligatures: Array<any> = [];
const unicode: string = '';
const svgPathData: string = 'M4.99633 60H77C79.2091 60 81 58.2091 81 56V4C81 1.79086 79.2091 0 77 0H4.99633C3.34408 2.19969 1.86903 4.54009 0.592377 7H74V53H0.592378C1.86903 55.4599 3.34408 57.8003 4.99633 60Z M38.9782 24C38.2751 24 37.8063 24.3906 37.572 25.0156C37.2595 25.6406 37.4157 26.1875 37.8845 26.6562L47.9626 36.7344C48.2751 37.0469 48.5876 37.2031 49.0563 37.2031C49.447 37.2031 49.8376 37.0469 50.1501 36.7344L60.2282 26.6562C60.697 26.1875 60.7751 25.6406 60.5407 25.0156C60.2282 24.3906 59.7595 24 59.1345 24H38.9782Z';

const flgSelection: IconDefinition = {
  prefix: 'flg' as IconPrefix,
  iconName: 'selection' as IconName,
  icon: [width, height, ligatures, unicode, svgPathData],
};

export default flgSelection;
