import { library, IconProp } from '@fortawesome/fontawesome-svg-core';
import { renderToString } from 'react-dom/server';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

// These are imported individually as you need them to save on the bundle size;
import { faEllipsisV } from '@fortawesome/pro-regular-svg-icons/faEllipsisV';
import { faEllipsisH } from '@fortawesome/pro-regular-svg-icons/faEllipsisH';
import { faPlus } from '@fortawesome/pro-regular-svg-icons/faPlus';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons/faChevronDown';
import { faChevronDown as solidChevronDown } from '@fortawesome/pro-solid-svg-icons/faChevronDown';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons/faCaretDown';
import { faChevronUp } from '@fortawesome/pro-regular-svg-icons/faChevronUp';
import { faAngleRight } from '@fortawesome/pro-regular-svg-icons/faAngleRight';
import { faTrash } from '@fortawesome/pro-regular-svg-icons/faTrash';
import { faHashtag } from '@fortawesome/pro-regular-svg-icons/faHashtag';
import { faPercent } from '@fortawesome/pro-regular-svg-icons/faPercent';
import { faCalculator } from '@fortawesome/pro-regular-svg-icons/faCalculator';
import { faCalendar } from '@fortawesome/pro-regular-svg-icons/faCalendar';
import { faClock } from '@fortawesome/pro-regular-svg-icons/faClock';
import { faCopy } from '@fortawesome/pro-regular-svg-icons/faCopy';
import { faUndo } from '@fortawesome/pro-regular-svg-icons/faUndo';
import { faFont } from '@fortawesome/pro-regular-svg-icons/faFont';
import { faLock } from '@fortawesome/pro-regular-svg-icons/faLock';
import { faLayerPlus } from '@fortawesome/pro-regular-svg-icons/faLayerPlus';
import { faMagic } from '@fortawesome/pro-regular-svg-icons/faMagic';
import { faCheck } from '@fortawesome/pro-regular-svg-icons/faCheck';
import { faCheck as solidCheck } from '@fortawesome/pro-solid-svg-icons/faCheck';
import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons/faExclamationTriangle';
import { faPlayCircle } from '@fortawesome/pro-regular-svg-icons/faPlayCircle';
import { faLongArrowLeft } from '@fortawesome/pro-regular-svg-icons/faLongArrowLeft';
import { faLongArrowRight } from '@fortawesome/pro-regular-svg-icons/faLongArrowRight';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons/faArrowLeft';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons/faArrowRight';
import { faPencilAlt } from '@fortawesome/pro-regular-svg-icons/faPencilAlt';
import { faPhone } from '@fortawesome/pro-regular-svg-icons/faPhone';
import { faEnvelope } from '@fortawesome/pro-regular-svg-icons/faEnvelope';
import { faSeedling } from '@fortawesome/pro-regular-svg-icons/faSeedling';
import { faComment } from '@fortawesome/pro-regular-svg-icons/faComment';
import { faQuestionCircle } from '@fortawesome/pro-regular-svg-icons/faQuestionCircle';
import { faStickyNote } from '@fortawesome/pro-regular-svg-icons/faStickyNote';
import { faLink } from '@fortawesome/pro-regular-svg-icons/faLink';
import { faUnlink } from '@fortawesome/pro-regular-svg-icons/faUnlink';
import { faEye } from '@fortawesome/pro-regular-svg-icons/faEye';
import { faFilter } from '@fortawesome/pro-regular-svg-icons/faFilter';
import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons/faInfoCircle';
import { faVoicemail } from '@fortawesome/pro-regular-svg-icons/faVoicemail';
import { faMapMarkerAlt } from '@fortawesome/pro-regular-svg-icons/faMapMarkerAlt';
import { faAlignLeft } from '@fortawesome/pro-regular-svg-icons/faAlignLeft';
import { faSyncAlt } from '@fortawesome/pro-regular-svg-icons/faSyncAlt';
import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons/faCheckCircle';
import { faCheckSquare } from '@fortawesome/pro-regular-svg-icons/faCheckSquare';
import { faFileDownload } from '@fortawesome/pro-regular-svg-icons/faFileDownload';
import { faAddressCard } from '@fortawesome/pro-regular-svg-icons/faAddressCard';
import { faFileAlt } from '@fortawesome/pro-regular-svg-icons/faFileAlt';
import { faCloudDownload } from '@fortawesome/pro-regular-svg-icons/faCloudDownload';
import { faTable } from '@fortawesome/pro-regular-svg-icons/faTable';
import { faCalendarEdit } from '@fortawesome/pro-light-svg-icons/faCalendarEdit';
import { faCalendarPlus } from '@fortawesome/pro-light-svg-icons/faCalendarPlus';
import { faTrashRestore } from '@fortawesome/pro-regular-svg-icons/faTrashRestore';
import { faUserPlus } from '@fortawesome/pro-regular-svg-icons/faUserPlus';
import { faSlidersH } from '@fortawesome/pro-regular-svg-icons/faSlidersH';
import { faUserSlash } from '@fortawesome/pro-regular-svg-icons/faUserSlash';
import { faPhoneSlash } from '@fortawesome/pro-regular-svg-icons/faPhoneSlash';
import { faTimesCircle } from '@fortawesome/pro-regular-svg-icons/faTimesCircle';
import { faSignature } from '@fortawesome/pro-regular-svg-icons/faSignature';
import { faSearch } from '@fortawesome/pro-regular-svg-icons/faSearch';
import { faCodeMerge } from '@fortawesome/pro-regular-svg-icons/faCodeMerge';
import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons/faSpinnerThird';
import { faFile } from '@fortawesome/pro-regular-svg-icons/faFile';
import { faFilePdf } from '@fortawesome/pro-regular-svg-icons/faFilePdf';
import { faFileWord } from '@fortawesome/pro-regular-svg-icons/faFileWord';
import { faFileImage } from '@fortawesome/pro-regular-svg-icons/faFileImage';
import flgCurrency from './icons/flgCurrency';
import flgAddress from './icons/flgAddress';
import flgChecklist from './icons/flgChecklist';
import flgDecimal from './icons/flgDecimal';
import flgDatetime from './icons/flgDatetime';
import flgRadio from './icons/flgRadio';
import flgSelection from './icons/flgSelection';
import { prepSvg } from './helpers';

library.add(
  faEllipsisH,
  faEllipsisV,
  faPlus,
  faChevronDown,
  faChevronUp,
  faAngleRight,
  faCaretDown,
  solidChevronDown,
  faTrash,
  faHashtag,
  faPercent,
  faCalculator,
  faCalendar,
  faClock,
  faFont,
  faLock,
  faLayerPlus,
  faMagic,
  faCheck,
  solidCheck,
  faVoicemail,
  faExclamationTriangle,
  faPlayCircle,
  faLongArrowLeft,
  faLongArrowRight,
  faArrowLeft,
  faArrowRight,
  faPencilAlt,
  faPhone,
  faEnvelope,
  faSeedling,
  faComment,
  faQuestionCircle,
  faStickyNote,
  faEye,
  faFilter,
  faTimes,
  faLink,
  faUnlink,
  faInfoCircle,
  faMapMarkerAlt,
  faAlignLeft,
  faSyncAlt,
  faInfoCircle,
  faCheckCircle,
  faCheckSquare,
  faUndo,
  faFileDownload,
  faFileAlt,
  faCloudDownload,
  faTable,
  faCalendarEdit,
  faCalendarPlus,
  faTrashRestore,
  faUserPlus,
  faUserSlash,
  faPhoneSlash,
  faAddressCard,
  faSlidersH,
  faTimesCircle,
  faSearch,
  faSpinnerThird,
  faFile,
  faFilePdf,
  faFileWord,
  faFileImage,
  // Custom icons
  flgCurrency,
  flgAddress,
  flgChecklist,
  flgDecimal,
  flgDatetime,
  flgRadio,
  flgSelection,
  faSignature,
  faCodeMerge,
  faCopy,
);

export default function renderIconHtml(icon: IconProp, color?: string): string {
  return prepSvg(
    renderToString(<FontAwesomeIcon icon={icon} color={color || 'white'} />),
  );
}
