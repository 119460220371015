export default {
  journeys: 'journeys',
  journeysAdmin: 'manageJourneys',
  // TODO - This is a bit out of date since we have four levels of Voice permission now:
  //   (a) no access because of service plan
  //   (b) access own calls only ('voice')
  //   (c) access group calls only ('manageGroupVoice'?)
  //   (d) manageSystemVoice
  voiceAdmin: ['manageSystemVoice', 'voice'],
  appointments: 'appointments',
  appointmentsAdmin: ['manageAppointments', 'appointments'],
  viewLeads: 'viewLeads',
  createLeads: 'createLead',
  /* TODO: I assume this *must* be the permission we use to update
    leads but I don't have a good way to check:
  */
  updateLeads: 'createLead',
  deleteLeads: 'deleteLead',
  manageIntegrations: 'manageSystemIntegrations',
};
