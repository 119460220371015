/* eslint-disable no-console */
const maxItemAge = 15 * 60 * 1000;

const clearExpiredCookies = () => {
  if (localStorage) {
    for (let i = 0; i < window.localStorage.length; i += 1) {
      const key = localStorage.key(i);

      if (!key) {
        return;
      }

      const { timestamp }: any = localStorage.getItem(key);

      if (Date.now() <= timestamp + maxItemAge) {
        console.log('Removing cache of', key);
        localStorage.removeItem(key);
      }
    }
  }
};

const setWithExpiry = (key: string, value: string, ttl: number) => {
  const item = {
    value: value,
    expiry: new Date().getTime() + ttl,
  };

  localStorage.setItem(key, JSON.stringify(item));
};

const getWithExpiry = (key: string) => {
  const itemString = localStorage.getItem(key);

  if (!itemString) {
    return null;
  }

  const item = JSON.parse(itemString);
  const isExpired = new Date().getTime() > item.expiry;

  if (isExpired) {
    localStorage.removeItem(key);
    return null;
  }

  return item.value;
};

export {
  clearExpiredCookies,
  maxItemAge,
  setWithExpiry,
  getWithExpiry,
};
