import * as React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { flgPurple } from '../theme';
import { IconButton } from './base';

export const HelpButtonBase = ({
  article,
  style,
  className,
} : {
  article: Number,
  style?: React.CSSProperties,
  className?: string,
}): React.ReactElement<'button'> => (
  <IconButton
    // eslint-disable-next-line no-underscore-dangle
    onClick={() => (window as any)._elev.openArticle(article.toString())}
    style={style}
    className={className}
  >
    <FontAwesomeIcon icon={['far', 'question-circle']} />
  </IconButton>
);

export const HelpButton = styled(HelpButtonBase)`
  color: ${flgPurple};
`;
