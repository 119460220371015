import * as loglevel from 'loglevel';

export function turnLoggingOn() {
  loglevel.setLevel('debug', true);
}

export function turnLoggingOff() {
  loglevel.disableAll(true);
}

// Sets logging to off if it hasn't been manually turned on.
export function defaultToOff() {
  loglevel.setDefaultLevel('silent');
}

// If this is a production build turn logging off by default, otherwise set level to debug.
if (process.env.NODE_ENV === 'production') {
  defaultToOff();
} else {
  turnLoggingOn();
}

export default loglevel;
