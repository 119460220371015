import * as React from 'react';
import styled, { keyframes } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { v4 as uuid } from 'uuid';
import * as Alerts from '.';
import {
  dangerRed, flgBlue, offBlack, flgGreen,
} from '../theme';
import { IconButton, InlineTextButton } from '../buttons';
import renderIconHtml from '../fontawesome';
import { device } from '../media-queries';

const ids: WeakMap<Alerts.Alert, string> = new WeakMap();

function clearAlert(alert: Alerts.Alert): void {
  const div = document.getElementById(`toast-${ids.get(alert) || ''}`);
  if (div) {
    div.style.opacity = '0';
    setTimeout(() => Alerts.clear(alert), 300);
  } else Alerts.clear(alert);
}

function register(alert: Alerts.Alert): string {
  // we've seen this before, get its ID
  const e = ids.get(alert);
  if (e) return e;
  if (alert.severity < Alerts.severity.pageFatal) {
    setTimeout(() => clearAlert(alert), alert.hideAfter || 5000);
  }
  // then generate an ID for it and return
  const n = uuid();
  ids.set(alert, n);
  return n;
}

// this is a bit of magic to animate the toasts dismissing
function shuffle() {
  requestAnimationFrame(() => {
    const rack = document.getElementById('toast-rack');
    if (!rack) return;
    let y = 16;
    const rackChildren = [...rack.children as any];
    for (let i = 0; i < rackChildren.length; i += 1) {
      const child = rackChildren[i];
      Object.assign(child.style, {
        top: '0',
        transform: `translateY(${y}px)`,
      });
      // apply the transition style a moment later
      // so it doesn't play when the div appears
      requestAnimationFrame(() => {
        child.style.transition = 'opacity 300ms, transform 300ms';
      });
      y += child.clientHeight + 16;
    }
  });
}

const ToastRackContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1100;
  pointer-events: none;
  overflow: hidden;
`;

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const Toast = styled.div<{ alertSeverity:number }>`
  ${({
    alertSeverity,
  }: any) => {
    switch (alertSeverity) {
      case Alerts.severity.success:
        return `background-color: ${flgGreen};
                background-image: ${renderIconHtml(['far', 'check'])};`;
      case Alerts.severity.warning:
        return `background-color: ${dangerRed};
                background-image: ${renderIconHtml(['far', 'exclamation-triangle'])};`;
      default:
        return `background-color: ${flgBlue};
                background-image: ${renderIconHtml(['far', 'info-circle'])};`;
    }
  }}
  background-size: 1.25em;
  background-position: 1em 1.3em;
  background-repeat: no-repeat;
  pointer-events: all;
  box-sizing: border-box;
  padding: 1.125em 1em 1.125em 3em;
  border-radius: 0.2em;
  box-shadow: 0 0.5em 2em ${offBlack}40;
  cursor: pointer;
  color: white;
  position: absolute;
  width: 19em;
  left: calc(50vw - 9.5em);

  @media ${device.tablet} {
    left: calc(50vw - 15em);
    width: 30em;
  }

  animation: ${fadeIn} 300ms;

  ${InlineTextButton} {
    color: white;
    font-weight: normal;
    font-family: -apple-system, system-ui, BlinkMacSystemFont, Helvetica, Arial, sans-serif;
    &:hover {
      color: white;
    }
  }
`;
const CloseButton = styled(IconButton)`
  position: absolute;
  width: 1em;
  height: 1em;
  top: 0.75em;
  right: 0.75em;
`;
const ToastMessage = styled.div`
  padding-right: 10px;
`;

export default function ToastRack(
  {
    alerts,
  }: {
    alerts: Alerts.Alert[]
  },
): React.ReactElement<'div'> {
  shuffle();
  return (
    <ToastRackContainer id="toast-rack">
      {alerts.map((alert) => {
        const id = register(alert);
        return (
          <Toast
            key={id}
            id={`toast-${id}`}
            alertSeverity={alert.severity}
            onClick={() => clearAlert(alert)}
          >
            <ToastMessage>{alert.message}</ToastMessage>
            {alert.nextSteps
              && alert.nextSteps.map(({ name, action }, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <InlineTextButton type="button" onClick={action} key={i}>
                  {name}
                </InlineTextButton>
              ))}
            <CloseButton type="button">
              <FontAwesomeIcon color="white" icon={['far', 'times']} />
            </CloseButton>
          </Toast>
        );
      })}
    </ToastRackContainer>
  );
}
